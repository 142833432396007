import {
    DeleteButton,
    List,
    TextField,
    ShowButton,
    DatagridConfigurable,
    useRecordContext,
    EditButton,
    CloneButton,
    SelectField,
    TopToolbar,
    ExportButton,
    CreateButton, useTranslate, useRedirect, SaveButton, useDataProvider, useNotify, useRefresh, DeleteWithConfirmButton
} from "react-admin";
import ConfigurableDatagrid from "../../components/ConfigurableDatagrid";
import EditIcon from '@mui/icons-material/Edit';
import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import {EmptyPage} from "../../components/EmtpyPage";
import {uuidv4} from "../../tools/generate";
import moment from "moment-timezone";
import {Button, Paper, ThemeProvider, Typography} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {muiDatagridTheme} from "../../components/MuiDatagridTheme";
import {DataGrid} from "@mui/x-data-grid";
import React from "react";
import {Link} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const PriceListTemplate = ({permissions}) => {
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const redirect = useRedirect()
    const PriceListPanel = () => {
        const record = useRecordContext()
        const priceLists = (record.priceLists && record.priceLists.length > 0) ? record.priceLists.map(it => {
            return {...it, internalId:uuidv4()}
        }) : null
        const format = "YYYY.MM.DD HH:mm"
        const translate = useTranslate()
        const redirect = useRedirect()
        const refresh = useRefresh()

        const columns = [
            {field: 'id', headerName: translate("priceListsOfPos.priceListId"), width: 70 },
            {field: "createdAt", headerName: translate("priceListTemplate.createdAt"), width: 300,
                valueFormatter: (params) => {
                    if (params.value == null) {
                        return '';
                    }else{
                        return moment(new Date(params.value)).format(format);
                    }
                }},
            {field: "state", headerName: translate("priceListTemplate.state"), width: 300,
                valueFormatter: (params) => {
                switch (params.value){
                    case 'DRAFT' :
                        return translate("priceListTemplate.draft")
                    case 'ACTIVATED':
                        return translate("priceListTemplate.activated")
                }
                }},
            {
                field: "action",
                headerName: translate("products.title"),
                sortable: false,
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        redirect(`/priceList/${params.row.id}/show`)
                    };
                    return <Button onClick={onClick}><VisibilityIcon/></Button>;
                }
            },
            {
                field: "action2",
                headerName: translate("priceListTemplate.edit"),
                sortable: false,
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        redirect(`/priceList/${params.row.id}`)
                    };
                    if(params.row.state === "DRAFT") {
                        return <Button onClick={onClick}><EditIcon/></Button>;
                    }else{
                        return <div></div>
                    }
                }
            },
            {
                field: "action3",
                headerName: translate("priceListTemplate.delete"),
                sortable: false,
                renderCell: (params) => {
                    const onClick = (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dataProvider.delete(`priceList`, {id:params.row.id})
                            .finally(() =>{
                            refresh();
                        });
                    };

                    if(params.row.state === "DRAFT") {
                        return <Button onClick={onClick}><DeleteIcon/></Button>;;
                    }else{
                        return <div></div>
                    }
                }
            },
        ]
        if(priceLists && priceLists.length > 0) {
            return (
                <Paper elevation={5} style={{height: "340px"}}>
                    <Typography style={{paddingTop: "8px", marginBottom: "8px", marginLeft: "8px"}}
                                variant={"h4"}>{translate("priceListsOfPos.priceLists")}</Typography>
                    <ThemeProvider theme={muiDatagridTheme}>
                        <DataGrid autoHeight={false} style={{height: "290px"}}
                                  rows={priceLists}
                                  columns={columns}
                                  disableSelectionOnClick={true}
                                  getRowId={(row) => row.internalId}
                                  initialState={{
                                      sorting: {
                                          sortModel: [{field: 'createdAt', sort: 'desc'}],
                                      },
                                  }}
                        />
                    </ThemeProvider>
                </Paper>
            )
        }else{
            return (
                <Paper elevation={5}>
                    <Typography variant={"h5"}>{translate("priceListTemplate.empty")}</Typography>
                </Paper>
            )
        }
    }

    const ButtonField = () => {
        const hasCreate = getValidPrivilegesIds("PRICELIST", "CREATE").some(r => permissions.includes(r))
        const record = useRecordContext();
        const handleClickCopy = () => {
            dataProvider.create(`priceList/`+record.id+`/copy`, {})
                .then(value => {
                    notify("priceListTemplate.created", {type: "success"})
                    redirect('edit', 'priceList', value.data.id)
                })
                .catch(() => {
                        notify("priceListTemplate.createFail", {type: "error"})
                    }
                )
        }
        return hasCreate ? <Button onClick={handleClickCopy}>{translate("priceListTemplate.createPriceList")}</Button> : null;
    }

    const ConditionalEditButton = ({permissions}) => {
        const hasEdit = getValidPrivilegesIds("PRICELIST", "EDIT").some(r => permissions.includes(r))
        let record = useRecordContext();
        record.clone = true
        record.oldId = record.id
        if(hasEdit) {
                return (
                    <EditButton record={record}/>
                )
            } else {
                return null
            }
    }
    const ListActions = () => {
        return(
            <TopToolbar>
                <CreateButton label={"priceListTemplate.createTemplate"} />
                <ExportButton />
            </TopToolbar>
        )
    };

    return (
        <List actions={<ListActions />} empty={<EmptyPage text={"priceListTemplate.empty"} createEnabled={true}/>}>
            <DatagridConfigurable expand={PriceListPanel} expandSingle={true} bulkActionButtons={false}>
                <TextField source="id" label={'priceListTemplate.id'}/>
                <TextField source="name" label={'priceListTemplate.name'}/>
                <ShowButton label={'products.title'}/>
                <ConditionalEditButton label={"priceListTemplate.edit"} permissions={permissions}/>
                <ButtonField label={"priceListTemplate.createPriceList"}/>
            </DatagridConfigurable>
        </List>

    )
}

export default PriceListTemplate