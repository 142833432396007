import {regex, required, TextInput} from "react-admin";
import React from "react";

const validateTaxNumber = regex(/^\d{8}\-[1-5]\-(?:0[2-9]|[13][0-9]|2[02-9]|4[0-4]|51)$/, 'Helyes formátum: xxxxxxxx-y-zz');

const taxNumberFormat = (v) => {
    if (v === undefined || v === null) {
        return v;
    }

    v = v.trim().replace(/[^0-9]/g, '');

    const format = 'xxxxxxxx-y-zz';

    let results = '';

    for (let i = 0, formatOffset = 0; i < v.length; i++) {
        if (format[i + formatOffset] === '-') {
            results += '-';
            formatOffset++;
        }

        results += v[i];
    }

    return results;
}

export const TaxNumberInput = ({...props}) => {
    return <TextInput validate={[validateTaxNumber]} format={taxNumberFormat} parse={taxNumberFormat}
                      inputProps={{maxLength: 13}} {...props}/>
}