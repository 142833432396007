import {
    Create,
    SimpleForm,
    ArrayInput, ReferenceInput, AutocompleteInput, required, TextInput, FormDataConsumer, useTranslate, useDataProvider,
    Edit
} from "react-admin";
import React, {useEffect, useState} from "react";
import {PosList} from "./PosList";
import {PinkToolbar} from "../../components/PinkToolbar";

export const PosSetCreate = ({permissions}) => {

    return(
        <Create title={"pos.title"}>
            <SimpleForm toolbar={<PinkToolbar deleteEnabled={false} type={"button"} /> }>
                <ReferenceInput source={"businessUnitId"} reference={'businessUnit/all'} perPage={200}>
                    <AutocompleteInput optionText={((record)=> {
                        return `${record.id} - ${record.name}`
                    })} fullWidth={true}
                                       label={"businessUnits.title"} validate={required()}
                                       required={true}/>
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData }) => (
                        <PosList formData={formData} />
                    )
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
}