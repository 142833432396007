import {
    Create,
    SimpleForm
} from "react-admin"
import {QuickMenu} from "./QuickMenu";
import {PinkToolbar} from "../../components/PinkToolbar";
const quickMenuTransform = (data) => {
    return { priceListId: data.priceListInstanceId ? data.priceListInstanceId : data.priceListId,
        quickMenu: data.layouts.map(it => {
            const product = data.products.filter(product => it.i === product.i)
            return {id: product[0].id, x: it.x, y: it.y, width: it.w, height: it.h}
        })
    }
}

export const QuickMenuCreate = () => {
    return (
    <Create transform={quickMenuTransform}>
        <SimpleForm toolbar={<PinkToolbar deleteEnabled={false} />}>
            <QuickMenu />
        </SimpleForm>
    </Create>
    )
}