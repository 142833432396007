import {DateInput, DateTimeInput} from "./DatePicker";
import {useTranslate} from "react-admin";
import moment from "moment-timezone";
import {getFormValue} from "../tools/form";

export const DateRangePicker = ({
                                    format = 'YYYY. MM. DD. HH:mm',
                                    source,
                                    label,
                                    minDate,
                                    maxDate,
                                    startDefault,
                                    endDefault,
                                    validate,
                                    maxInterval = undefined,
                                    disabled,
                                    isTimeRequired = true,
                                    type = "CURRENT_TIME",
                                    ...props
                                }) => {

    const translate = useTranslate();

    const sources = {
        start: 'start',
        end: 'end'
    }
    if (typeof source === 'string') {
        sources.start = source + '.start';
        sources.end = source + '.end';
    } else {
        sources.start = source.start;
        sources.end = source.end;
    }

    const labels = {
        start: 'report.date.start',
        end: 'report.date.end'
    }
    if (label !== null && label !== undefined) {
        if (typeof label === 'string') {
            labels.start = label + ` (${translate('report.date.start').toLowerCase()})`;
            labels.end = label + ` (${translate('report.date.end').toLowerCase()})`;
        } else {
            labels.start = label.start;
            labels.end = label.end;
        }
    }

    const dateValidator = (value, values) => {
        const rawStart = getFormValue(values, sources.start);
        const rawEnd = getFormValue(values, sources.end);

        if (Date.parse(rawStart) >= Date.parse(rawEnd)) {
            return 'report.date.endBeforeStartError';
        }

        if (maxInterval !== undefined) {
            const start = moment(rawStart);
            const end = moment(rawEnd);

            const min = end.subtract(maxInterval).startOf('day')

            if (min.isAfter(start)) {
                return translate('ntkr.date.intervalTooLargeError', {days: maxInterval.asDays()})
            }
        }
    }

    const validators = validate ? [dateValidator].concat(validate) : [dateValidator];


    //const minDate = moment().subtract(1, "year");
    if (isTimeRequired) {
        return (
            <div>
                <DateTimeInput source={sources.start} label={labels.start} options={{
                    format: format,
                    ampm: false,
                    minDate: minDate,
                    maxDate: maxDate,
                    clearable: true,
                    disableFuture: false,
                    type: "CURRENT_TIME"
                }} validate={validators} defaultValue={startDefault} disabled={disabled}/>
                <span>&nbsp;</span>
                <DateTimeInput source={sources.end} label={labels.end}  options={{
                    format: format,
                    ampm: false,
                    minDate: minDate,
                    maxDate: maxDate,
                    clearable: true,
                    disableFuture: false,
                    type: "CURRENT_TIME"
                }} validate={validators} defaultValue={endDefault} disabled={disabled}/>
            </div>
        )
    } else {
        return (
            <div>
                <DateInput source={sources.start} label={labels.start} options={{
                    format: format,
                    ampm: false,
                    minDate: minDate,
                    maxDate: maxDate,
                    clearable: true,
                    disableFuture: false,
                    type: "START_OF_DAY"
                }} validate={validators} defaultValue={startDefault} disabled={disabled}/>
                <span>&nbsp;</span>
                <DateInput source={sources.end} label={labels.end} options={{
                    format: format,
                    ampm: false,
                    minDate: minDate,
                    maxDate: maxDate,
                    clearable: true,
                    disableFuture: false,
                    type: "END_OF_DAY"
                }} validate={validators} defaultValue={endDefault} disabled={disabled}/>
            </div>
        )
    }
}