import {Create, SimpleForm, FormDataConsumer} from "react-admin";
import {UserDetails} from "./UserDetails";
import {userTransform} from "../../tools/userTransform";
import {PinkToolbar} from "../../components/PinkToolbar";
import React from "react";

export const TechnicalUserCreate = () => {
    return (
        <Create transform={userTransform}>
            <SimpleForm toolbar={<PinkToolbar alwaysEnableSave={true} deleteEnabled={false} />} mode={"all"}>
                <FormDataConsumer>
                    {({formData}) => (
                     <UserDetails formData={formData} edit={false} />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
}