export const getFormValue = (values, route) => {
    if (!values || !route) {
        return undefined;
    }

    const pcs = route.split('.');

    let obj = values;

    for (let pc = pcs.shift(); pc !== undefined; pc = pcs.shift()) {
        obj = obj[pc];

        if (obj === undefined) {
            return undefined;
        }

        if (typeof obj !== 'object' && pcs.length > 0) {
            return undefined;
        }
    }

    return obj;
}