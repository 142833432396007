import {required, TextInput, number, minLength, maxLength, useTranslate, PasswordInput} from "react-admin";
import React, {useState} from "react";
import PatternIcon from '@mui/icons-material/Pattern';
import {Button} from "@mui/material";

export const TechnicalUserData = ({edit,...props}) => {
    const [resetPIN, setResetPIN] = useState(false)
    const translate = useTranslate()

    const pinValidation = (value, allValues) => {
        if (allValues.pinCodeAgain !== allValues.pinCode) {
            return 'technicalUsers.validation.pinDontMatch';
        }
        return undefined;
    };
    const handleAddChild = () => {
        setResetPIN(true)
    }

    return (
        <div style={{display: 'inline-flex', flexDirection: 'column'}}  {...props}>
            <TextInput source="name" label={"technicalUsers.name"} inputProps={{maxLength: 255}} validate={[required()]} />
            {edit ?
                <Button style={{marginBottom: '8px', color: "white"}} variant={'contained'} color={"primary"}
                        onClick={handleAddChild}><PatternIcon/> {translate("technicalUsers.resetPIN")}</Button>
                : null
            }
            {(!edit || resetPIN) &&
            <div style={{display: 'inline-flex', flexDirection: 'column'}}>
                <PasswordInput source="pinCode" label={"technicalUsers.pinCodeCreate"}
                           inputProps={{maxLength: 4}} validate={[required(), number(), minLength(4), maxLength(4)]}/>
                <PasswordInput source="pinCodeAgain" label={"technicalUsers.pinCodeAgain"}
                           inputProps={{maxLength: 4}}
                           validate={[required(), number(), minLength(4), maxLength(4), pinValidation]}/>
            </div>
            }
        </div>
    )
}