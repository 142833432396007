import {
    Edit,
    required,
    SaveButton,
    SimpleForm,
    useDataProvider,
    useNotify,
    Create,
    Toolbar,
    ReferenceInput,
    useRefresh,
    AutocompleteInput
} from 'react-admin'
import React, {useEffect} from "react";

export const ActivePosSetSelector = props => {
    const notify = useNotify();
    const [data, setData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const dataProvider = useDataProvider()
    const refresh = useRefresh()

    const ActivePosSetToolbar = () => {

        return (
            <Toolbar>
                <SaveButton
                    label="activePosSets.change"
                    mutationOptions={{
                        onSuccess: () => {
                            notify('activePosSets.change', { type: 'success' });
                            dataProvider.get("auth/me").then(
                                value => {
                                    localStorage.setItem('permission', JSON.stringify( value.data.privileges.map(it => it.id)))
                                    window.location.reload(false);
                                    return  value.data.privileges.map(it => it.id)
                                }
                            )
                            refresh()
                        }}
                    }
                    type="button"
                    variant="text"
                />
            </Toolbar>
        )
    }

    useEffect(() => {
        if (!data.loading && !data.loaded) {
            setData({...data, loading: true});
            dataProvider.get("activePosSet/current")
                .then(value => {
                    console.log("qq", value.data)
                    setData({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [data.loading, data.loaded]);

   if(data.data?.newId) {
       return (
           <Create title={"activePosSets.title"}>
           <SimpleForm toolbar={<ActivePosSetToolbar />}>
                   <ReferenceInput source="newId" perPage={100}
                                   reference="activePosSet" sort={{ field: 'name', order: 'ASC' }}>
                       <AutocompleteInput fullWidth={true}  optionText={((record)=> {
                           return `${record.name} (${record.company})`
                       })} label={"activePosSets.posSets"}
                                    defaultValue={data.data.newId} validate={required()}/>
                   </ReferenceInput>
           </SimpleForm>
           </Create>
       )
   }else{
       if(data.error?.status === 400){
           return(
           <Create>
               <SimpleForm toolbar={<ActivePosSetToolbar />}>
                   <ReferenceInput source="newId" perPage={100}
                                   reference="activePosSet">
                       <AutocompleteInput fullWidth={true} optionText={"name"} label={"activePosSets.posSets"}
                                    validate={required()}/>
                   </ReferenceInput>
               </SimpleForm>
           </Create>
           )
       }
       return (
           <div></div>
       )
   }
}