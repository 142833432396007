import {
    TextInput,
    FormDataConsumer,
    SimpleForm, required,
    Toolbar,
    SaveButton,
    DeleteButton, useRecordContext, CloneButton, useDataProvider, useNotify, useRedirect, useTranslate
} from "react-admin";
import React from "react";
import {ProductToPriceList} from "../../components/ProductToPriceList";
import {useFormContext, useFormState} from "react-hook-form";
import {Button, Typography} from "@mui/material";
import SaveAsIcon from '@mui/icons-material/SaveAs';



export const PriceListTemplateDetails = (props) => {
    let record = useRecordContext()
    if(!record){
        record = {}
    }

    const AlwaysEnableToolbar = () => {
        const { setValue } = useFormContext()
        const { isDirty, isValid } = useFormState()
        setValue("createNewPriceList", false)
        const handleCreatePriceListClick = (e) => {
            setValue("createNewPriceList", true)
        }

        return (
        <Toolbar {...props} >
                <>
                    {record.type === "INSTANCE" ?
                        <SaveButton label={"priceListTemplate.saveAsInstance"} icon={<SaveAsIcon />} variant={"outlined"}  type={"button"}
                                alwaysEnable={isValid}/>
                        :
                        <SaveButton label={"priceListTemplate.saveAsTemplate"} icon={<SaveAsIcon />} variant={"outlined"}  type={"button"}
                                    alwaysEnable={isValid}/>
                    }
                </>

        </Toolbar>
    );
}

    return (
        <SimpleForm toolbar={<AlwaysEnableToolbar />}>
                <TextInput source={"name"} disabled={record.type === "INSTANCE"} label={'priceListTemplate.name'} validate={required()} />
                <FormDataConsumer>
                    {({ formData }) => (
                    <ProductToPriceList formData={formData} />
                        )
                    }
                </FormDataConsumer>
        </SimpleForm>
    )
}