import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import {
    List,
    TextField,
    DatagridConfigurable,
    TopToolbar,
    SelectColumnsButton,
    CreateButton,
    FilterButton,
    ExportButton,
    useRecordContext
} from "react-admin";
import {EmptyPage} from "../../components/EmtpyPage";
import {PinkActions} from "../../components/PinkActions";
import {PhoneField} from "../../components/PhoneField";



export const CompanyList = ({permissions, ...props}) => {
    const hasEdit = getValidPrivilegesIds("BUSINESS_UNIT", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    return(
        <List actions={<PinkActions hasCreate={true}/>} title={"businessUnits.title"} empty={<EmptyPage text={"company.empty"} createEnabled={true} />}>
            <DatagridConfigurable size="small"  omit={['id', 'euTaxNumber', 'companyRegistrationNumber', 'contactPhone']} rowClick={"edit"} bulkActionButtons={false} >
                <TextField source="id" label={'company.id'} />
                <TextField source="name" label={'company.name'} />
                <TextField source="zipCode" label={'company.zipCode'} />
                <TextField source="settlement" label={'company.settlement'} />
                <TextField source="address" label={'company.address'} />
                <TextField source="taxNumber" label={'company.taxNumber'} />
                <TextField source="euTaxNumber" label={'company.euTaxNumber'} />
                <TextField source="companyRegistrationNumber" label={'company.companyRegistrationNumber'} />
                <TextField source="contactName" label={'company.contactName'} />
                <TextField source="contactEmail" label={'company.contactEmail'} />
                <PhoneField source={"contactPhone"} label={"company.contactPhone"} />


            </DatagridConfigurable>
        </List>
    )
}