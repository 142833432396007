import React from 'react';
import {
    TextInput,
    ReferenceInput,
    FormDataConsumer,
    SimpleFormIterator,
    ArrayInput,
    AutocompleteInput,
    required, NumberInput, minValue, useTranslate, useDataProvider, useRecordContext
} from "react-admin";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {AutocompleteSimpleInput} from "../../components/AutocompleteSimpleImput";
import {useFormContext} from "react-hook-form";
import {useEffect} from "react";

const ProductDetails = () => {
    const {setValue} = useFormContext();
    const record = useRecordContext();
    const defaultTags = record !== undefined && record.tags.length > 0 ? record.tags.map(value => value.name) : []
    const translate = useTranslate();
    const [tag, setTag] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [selectedTag, setSelectedTag] = React.useState([]);
    const dataProvider = useDataProvider()
    //Ez kell ide, vagy fefülírja a táblázat, amíg nincs módosítva. Ezt át lehetne rakni backend részre, hogy tag vagy név tömbként vagy név id listaként megy fel
    setValue('tags', selectedTag)


    const handleDisableRemove = (record) => {
        return record.id
    };

    const limitTo2Decimal = (e) => {
        e.target.value = +parseFloat(e.target.value).toFixed( 2 )
    }

    const handleChange = (event, newValue) => {
        setValue('subCategoryId', null)
    };

    const handleTag = (event, newValue) => {
        //setValue('tags', newValue) //Ez majd akkor kell, ha átkerül be-re a fenti
        setSelectedTag(newValue)
    }

    useEffect(() => {
        if(defaultTags !== undefined) {
            //setValue('tags', defaultTags) //Ez majd akkor kell, ha átkerül be-re a fenti
            setSelectedTag(defaultTags)
        }
    },[record]);


    useEffect(() => {
        if (!tag.loading && !tag.loaded) {
            setTag({...tag, loading: true});
            dataProvider.get(`businessUnit/tag`)
                .then(value => {
                     //setTag({loading: false, loaded: true, data: value.data, error: undefined});
                     setTag({loading: false, loaded: true, data: (value.data.map(value => value.name)), error: undefined});
                })
                .catch(reason => {
                    setTag({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [tag.loading, tag.loaded]);

    return (
        <>
            <TextInput source="name" label={"products.name"} validate={required()} inputProps={{maxLength: 255}}/>

            <AutocompleteSimpleInput source={"mainCategoryId"} label={"products.mainCategory"}
                                     multiple={false}
                                     reference={'product/mainCategory'}
                                     sortField={"name"}
                                     optionText={"name"}
                                     validate={required()} onChange={handleChange}/>
            <FormDataConsumer>
                {({ formData }) => (
                    formData.mainCategoryId &&
                <AutocompleteSimpleInput source={"subCategoryId"} label={"products.subCategory"}
                                         multiple={false}
                                         reference={'product/subCategory'}
                                         sortField={"name"}
                                         optionText={"name"} filter={{mainCategoryId : formData.mainCategoryId}}
                                         validate={required()}/>
                )}
            </FormDataConsumer>


            <Autocomplete
                multiple
                id="tags"
                getOptionLabel={ (data) => data?.toString() }
                options={ tag.data } //{ tag.data.map(value => value.name || value)}
                value={ selectedTag }
                freeSolo
                fullWidth
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                onChange={handleTag}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="filled"
                        label={translate('products.tags')}
                    />
                )}
            />


            <ReferenceInput source={"vatPercentage"}  reference={'product/vatGroup'}>
                <AutocompleteInput  optionText={"name"} label={"products.vatGroup"} defaultValue={27} validate={required()}/>
            </ReferenceInput>

            <ArrayInput source={"units"} label={"products.productUnits"}>
                <SimpleFormIterator disableReordering={true} disableRemove={handleDisableRemove}>
                    <ReferenceInput source={"unit"} validate={required()}  reference={'product/unit'}>
                        <AutocompleteInput  optionText={"name"} label={"products.unit"} validate={required()}/>
                    </ReferenceInput>
                    <NumberInput source={"quantity"} onChange={limitTo2Decimal} label={"products.quantity"} onWheel={(e) => e.target.blur()} validate={[required(), minValue(0, "products.greaterThanZero")]}/>
                    <NumberInput source={"defaultPrice"}  label={"products.defaultPrice"} onWheel={(e) => e.target.blur()} validate={[required(), minValue(0, "products.greaterThanZero")]}/>
                </SimpleFormIterator>
            </ArrayInput>
        </>
    )
}

export default ProductDetails