import * as React from 'react';
import {DataGrid, huHU} from '@mui/x-data-grid';
import {useFormContext} from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './ListItems.css'
import {useState} from "react";
import EditIcon from "@mui/icons-material/Edit";
import {useTranslate} from "react-admin";
import {Button, Dialog, DialogContent, TextField} from "@mui/material";
import {muiDatagridTheme} from "./MuiDatagridTheme";


export default function EditPriceDataGrid({rows}) {

    const columns = [
        {
            field: 'name',
            headerName: 'Név',
            width: 350,
            valueGetter: (params) => params.row.product.name
        },
        {
            field: 'unit',
            headerName: 'Kiszerelés'
        },
        {
            field: 'price',
            headerName: 'Ár',
            type: "number",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return '';
                }else{
                    return `${params.value} Ft`
                }
            }

        },
        {
            field: "editAction",
            headerName: "Módosítás",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    setOpen(true)
                    setEditValue(params.row)
                };

                return <Button onClick={onClick}><EditIcon/></Button>;
            }
        },
        {
            field: "action",
            headerName: "Törlés",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const newRows = rows.filter(row => {
                        return row.id !== params.id
                    })
                    setValue('products', newRows)

                };

                return <Button onClick={onClick}><DeleteIcon/></Button>;
            }
        },

    ];

    const { setValue } = useFormContext();
    const [open, setOpen] = useState(false)
    const [newPrice, setNewPrice] = useState(null)
    const [editValue, setEditValue] = useState(null)
    const translate = useTranslate()

    const processRowUpdate = React.useCallback(
        async (newRow) => {
            const newRows = rows.map(r => (r.id === newRow.id) ? {...r, price: newRow.price} : r )
            setValue('products', newRows)
        },
        [],
    );

    const handleClick = (event) => {
        const newRows = rows.map(row => {
            if(row.id !== editValue.id){
                return row
            }else{
                console.log("qqq",row, newPrice)
                return {...row, price: parseInt(newPrice)}
            }
        })
        setValue('products', newRows)
        setOpen(false)

    };

    const onProcessRowUpdateError = (error) => {
        console.log(error)
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            handleClick(e)
        }
    };

    const handleDialogClose = () => {
        setOpen(false)
        setNewPrice(null)
    }


    const handleTextChange = (event) => {
        setNewPrice(event.target.value)
    }


    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleDialogClose} >
                <DialogContent>
                    <TextField label={translate("priceListTemplate.price")} type={"number"} autoFocus
                               defaultValue={editValue?.price}  onWheel={(e) => e.target.blur()}
                               onKeyPress={handleKeypress} onChange={handleTextChange}  />
                    <div style={{marginTop: "16px", display:"flex", justifyContent: "space-between"}}>
                        <Button style={{paddingRight: "8px", color: "white"}} variant={"contained"} color={"primary"} disabled={!newPrice} onClick={handleClick}>{translate("priceListTemplate.modify")}</Button>
                        <Button variant={"outlined"} color={"secondary"} onClick={handleDialogClose}>{translate("priceListTemplate.cancel")}</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <div style={{ height: 400, width: 1000 }}>
                <ThemeProvider theme={muiDatagridTheme}>
                    <DataGrid
                        rowsPerPageOptions={[5, 10, 20]}
                        rows={rows}
                        columns={columns}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={onProcessRowUpdateError}
                    />
                </ThemeProvider>
            </div>
        </React.Fragment>
    );
}