import React from 'react';
import ConfigurableDatagrid from "../../components/ConfigurableDatagrid";
import {
    TextField,
    List,
    useRecordContext, useTranslate, useRedirect, TextInput, DatagridConfigurable
} from "react-admin";
import {DataGrid, huHU} from "@mui/x-data-grid";
import moment from "moment-timezone";
import {Button, Paper, ThemeProvider, Typography} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {createTheme} from "@mui/material/styles";
import {muiDatagridTheme} from "../../components/MuiDatagridTheme";
import {uuidv4} from "../../tools/generate";



const PriceListPanel = () => {
    const record = useRecordContext()
    console.log(record)
    const priceLists = record.priceLists.map(it => {
        return {...it, internalId:uuidv4()}
    });
    const format = "YYYY.MM.DD HH:mm"
    const translate = useTranslate()
    const redirect = useRedirect()

    const columns = [
        {field: 'id', headerName: translate("priceListsOfPos.priceListId"), width: 70 },
        {field: 'name', headerName: translate("priceListsOfPos.priceListName"), width: 300 },
        {field: "createdAt", headerName: translate("priceListsOfPos.createdAt"), width: 300,
            valueFormatter: (params) => {
                if (params.value == null) {
                    return '';
                }else{
                    return moment(new Date(params.value)).format(format);
                }
            }},
        {field: "validFrom", headerName: translate("priceListsOfPos.priceListValidFrom"), width: 300,
            valueFormatter: (params) => {
                if (params.value == null) {
                    return '';
                }else{
                    return moment(new Date(params.value)).format(format);
                }
            }},
        {
            field: "action",
            headerName: translate("priceListsOfPos.open"),
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    redirect(`/priceList/${params.row.id}/show`)
                };
                    return <Button onClick={onClick}><VisibilityIcon/></Button>;
                }
        },
    ]

    return (
        <Paper elevation={5} style={{height: "340px"}}>
            <Typography style={{paddingTop: "8px", marginBottom: "8px", marginLeft: "8px"}} variant={"h4"}>{translate("priceListsOfPos.priceLists")}</Typography>
            <ThemeProvider theme={muiDatagridTheme}>
                <DataGrid autoHeight={false} style={{height: "290px"}}
                rows={priceLists}
                columns={columns}
                disableSelectionOnClick={true}
                getRowId={(row) => row.internalId}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'validFrom', sort: 'desc' }],
                    },
                }}
            />
            </ThemeProvider>
        </Paper>
    )
}

export const ListPriceListsOfPos = () => {

    const PosFilters = [
        <TextInput label="priceListsOfPos.search" source="q" alwaysOn />
    ];
    return (
        <List filters={PosFilters} title={"priceListsOfPos.title"}>
            <DatagridConfigurable bulkActionButtons={false} expand={<PriceListPanel />} expandSingle={true}
                                  sx={{
                                      '& .column-id': { width: "100px" },
                                  }}
            >
                <TextField source="id" label={'priceListsOfPos.id'}/>
                <TextField source="name" label={'priceListsOfPos.name'}/>
            </DatagridConfigurable>
        </List>
    )
}