import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/x-data-grid";

export const muiDatagridTheme = createTheme(
    {
        palette: {
            primary: { main: '#ff71bc' },
        },
    },
    huHU,
);