import React from 'react';
import {Edit, SimpleForm} from "react-admin";
import ProductDetails from "./ProductDetails";
import {PinkToolbar} from "../../components/PinkToolbar";

const ProductEdit = (props) => {
    return (
        <Edit mutationMode="pessimistic" {...props} redirect={"list"} title={'products.create'}>
            <SimpleForm toolbar={<PinkToolbar deleteEnabled={false} alwaysEnableSave={true} />}>
                <ProductDetails />
            </SimpleForm>
        </Edit>
    )

}
export default ProductEdit