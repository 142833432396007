import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {FieldTitle, useInput, useTranslate} from 'react-admin';
import {DatePicker, DateTimePicker, MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';

const Picker = ({PickerComponent, ...fieldProps}) => {
    const {
        options,
        label,
        source,
        resource,
        validate,
        className,
        isRequired,
        providerOptions,
        variant,
        //defaultValue,
        disabled,
        helperText,
        type
    } = fieldProps;

    const {
        defaultValue,
        initialValue,
        input: _input,
        ...sanitizedInputProps
    } = fieldProps;

    const translate = useTranslate();

    const [forgetInitValue, setForgetInitValue] = useState(false);

    const req = useMemo(() => {
        if (Array.isArray(validate)) {
            for (let validator of validate) {
                if (validator.isRequired) {
                    return true;
                }
            }
        } else if (typeof validate === 'function') {
            if (validate.isRequired) {
                return true;
            }
        }

        return isRequired;
    }, [validate, isRequired]);

    const defaultInput = useMemo(() => {
        //to delete init value
        /*  if (forgetInitValue) {
            return undefined;
        }

       */

        const value = initialValue ?? defaultValue;
        if (Date.parse(value)) {
            switch (options.type) {
                case "START_OF_DAY" :
                    return moment(value).startOf('day').toISOString()
                case "END_OF_DAY":
                    return moment(value).endOf('day').toISOString()
                case "CURRENT_TIME":
                default:
                    return moment(value).toISOString();
            }
        }

        return undefined;
    }, [defaultValue, initialValue, options.type, forgetInitValue]);

    const {field, fieldState, ...rest} = useInput({
        defaultValue: defaultInput,
        ...sanitizedInputProps
    });

    const {isTouched, error, invalid} = fieldState;

    useEffect(() => {
        if (!forgetInitValue) {
            setForgetInitValue(true);
        }
    }, [forgetInitValue, isTouched, invalid]);

    const handleChange = useCallback(value => {
        switch (options.type) {
            case "CURRENT_TIME":
                Date.parse(value) ? field.onChange(moment(value).toISOString()) : field.onChange(undefined);
                break;
            case "START_OF_DAY":
                Date.parse(value) ? field.onChange(moment(value).startOf('day').toISOString()) : field.onChange(undefined);
                break;
            case "END_OF_DAY" :
                Date.parse(value) ? field.onChange(moment(value).endOf('day').toISOString()) : field.onChange(undefined);
                break;
            default:
                Date.parse(value) ? field.onChange(moment(value).toISOString()) : field.onChange(undefined);
                break;
        }
        field.onBlur();
    }, [field, options.type]);

    //const defaultMaterialTheme = createMuiTheme();

    // <ThemeProvider theme={defaultMaterialTheme}>
    const isError = !!(isTouched && error);
    const helper = isError ? translate(error.message) : helperText; // touched && translate(error)



    const picker = (
        <MuiPickersUtilsProvider {...providerOptions}>
            <PickerComponent
                {...options}
                label={<FieldTitle
                    label={translate(label)}
                    source={source}
                    resource={resource}
                    isRequired={req}
                />}
                clearLabel={translate("report.reset")}
                margin="normal"
                disabled={disabled}
                inputVariant={variant ? variant : 'filled'}
                error={isError}
                helperText={helper}
                className={className}
                value={field.value ? moment(field.value).tz('Europe/Budapest') : null}
                onChange={date => handleChange(date)}
                onBlur={e => field.onBlur(e)}
            />
        </MuiPickersUtilsProvider>
    );

    return (
        picker
    )
}

Picker.propTypes = {
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    labelTime: PropTypes.string,
    className: PropTypes.string,
    providerOptions: PropTypes.shape({
        utils: PropTypes.func,
        locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
};

Picker.defaultProps = {
    input: {},
    isRequired: false,
    meta: {touched: false, error: false},
    options: {},
    resource: '',
    source: '',
    labelTime: '',
    className: '',
    providerOptions: {
        utils: MomentUtils,
        locale: 'hu',
    },
};

export const DateInput = props => <Picker PickerComponent={DatePicker} {...props} />
export const TimeInput = props => <Picker PickerComponent={TimePicker} {...props} />
export const DateTimeInput = props => <Picker PickerComponent={DateTimePicker} {...props} />

DateTimeInput.propTypes = {
    clearable: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    validate: PropTypes.any,
    defaultValue: PropTypes.any,
    helperText: PropTypes.string
};