import {Datagrid, List, DeleteWithConfirmButton, TextField, BooleanField, useRecordContext, useTranslate} from "react-admin";
import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import {Typography} from "@mui/material";
import {EmptyPage} from "../../components/EmtpyPage";


export const DeviceList = ({permissions})  => {
    const hasEdit = getValidPrivilegesIds("DEVICE", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    const hasDelete = getValidPrivilegesIds("DEVICE", "DELETE").some(r => permissions.includes(r)) ? "edit" : undefined

    const DeleteField = ( props) => {
        const record = useRecordContext();
        const translate = useTranslate()
        if(hasDelete && !record.isShiftOpen) {
            return (
                <DeleteWithConfirmButton confirmTitle={"device.detachAreYouSure"} confirmContent={`${record.deviceId} ${record.deviceName}`} mutationMode={"pessimistic"} label={"device.remove"}/>
            )
        }else{
            if(hasDelete){
                return(
                    <Typography>{translate("device.cantRemove")}</Typography>
                )
            }
        }

    }

    return (
        <List title={"device.title"} empty={<EmptyPage text={"device.empty"} />}>
            <Datagrid bulkActionButtons={false} rowClick={hasEdit}>
                <TextField source="id" label={'device.posId'}/>
                <TextField source="posName" label={'device.posName'}/>
                <TextField source="deviceId" label={'device.deviceId'}/>
                <TextField source="deviceName" label={'device.deviceName'}/>
                <BooleanField source={"isShiftOpen"} label={'device.shiftOpen'} sortable={false} />
                <DeleteField label={'device.removeShort'}/>
            </Datagrid>
        </List>
    )
}