import {Show, TextField, SimpleShowLayout, Datagrid, ArrayField} from 'react-admin'
import React from "react";

export const PriceListTemplateShow = () => {
    return(
        <Show>
        <SimpleShowLayout>
            <TextField source="name" label={'priceListTemplate.name'} />
            <ArrayField source="products" label="Termékek">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="product.name" label={'products.name'} />
                    <TextField source="price" label={'products.price'} />
                    <TextField source="product.mainCategory" label={'products.mainCategory'} />
                    <TextField source="product.subCategory" label={'products.subCategory'} />
                    <TextField source="product.vatGroup" label={'products.vatGroup'} />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
    )
}