import React, {useEffect} from "react";
import RecursiveTreeView from "../../components/RecursiveTreeView";
import {useDataProvider} from "react-admin";
import {useFormContext} from "react-hook-form";
import {LinearProgress} from "@mui/material";

export const SelectPosSets = ({index, formDataCurrentPosSets, formDataPosSets, source, updatedResourceName, disableAlreadySelected}) => {
    const dataProvider = useDataProvider()
    const [data, setData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [selected, setSelected] = React.useState(index.posSets ? index.posSets : []);
    const {setValue} = useFormContext();

    useEffect(() => {
        if (!data.loading && !data.loaded) {
            setData({...data, loading: true});
            dataProvider.get(source)
                .then(value => {
                    setData({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [data.loading, data.loaded]);

    useEffect(() => {
        if (formDataCurrentPosSets) {
            let newRoles = formDataCurrentPosSets.map(role => {
                if (role && role.id === index.id && selected !== role.posSets) {
                    return {...role, posSets: selected}
                }
            })


            let finalRows = []
            for (let i = 0; i < newRoles.length; i++) {
                if (newRoles[i]) {
                    finalRows.push(newRoles[i])
                } else {
                    if(formDataPosSets) {
                        finalRows.push(formDataPosSets[i])
                    }
                }
            }
            setValue(updatedResourceName, finalRows)

        }
    }, [selected, formDataCurrentPosSets, index]);

    if (data.loading || !data.loaded) {
        return (
            <LinearProgress/>
        )
    }
    if(index.id) {
        return (
            <div style={{display: 'inline-flex', flexDirection: 'column'}}>
                <RecursiveTreeView data={data.data} selected={selected} setSelected={setSelected}
                                   disabledList={disableAlreadySelected ? index.posSets : []}/>
            </div>

        )
    }

}