import * as React from 'react';
import {LocalesMenuButton, AppBar, ToggleThemeButton, defaultTheme, RefreshButton, useTranslate} from 'react-admin';
import {useEffect} from "react";
import dataProvider from "./dataProvider";
import StorefrontIcon from '@mui/icons-material/Storefront';
import {Link} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import logo from '../assets/pinkpos-logo.svg';

const CurrentBusinessUnit = ({data}) => {
    const translate = useTranslate()
    const businessUnits = JSON.parse(localStorage.getItem("businessUnits"))
    if(data?.data?.name) {
        return (
            <Link to="/activePosSet" style={{textDecoration: 'inherit', color: 'inherit'}}>
                <Button color={'inherit'}>
                    <div style={{display: "flex", paddingLeft: "16px"}}>
                        <StorefrontIcon style={{paddingRight: '8px'}}/>

                        <Typography>{data.data.name}</Typography>

                    </div>
                </Button>
            </Link>
        )
    }else{
        if(businessUnits?.length > 0){
            return (
                <Link to="/activePosSet" style={{textDecoration: 'inherit', color: 'inherit'}}>
                    <Button color={'inherit'}>
                        <div style={{display: "flex", paddingLeft: "16px"}}>
                            <StorefrontIcon style={{paddingRight: '8px'}}/>

                            <Typography color={"inherit"}>{translate("activePosSets.setActivePosSet")}</Typography>

                        </div>
                    </Button>
                </Link>
            )
        }else{
            return (
                <div style={{display: "flex", paddingLeft: "16px"}}>
                    <StorefrontIcon style={{paddingRight: '8px'}}/>

                    <Typography color={"inherit"}>{translate("activePosSets.noActive")}</Typography>

                </div>
            )
        }
    }
}


export const PinkAppBar = props => {
    const [data, setData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const activePosSetId = localStorage.getItem('activePosSetId')
    const translate = useTranslate()

    window.onfocus = function() {
        if(data.data.id.toString() !== localStorage.getItem('activePosSetId').toString()) {
            window.location.reload(false);
        }
    };

    useEffect(() => {
        if (!data.loading && !data.loaded && activePosSetId !== null) {
            setData({...data, loading: true});
            dataProvider.get(`businessUnit/${activePosSetId}`)
                .then(value => {
                    setData({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [])

    return(
        <AppBar
            sx={{
                "& .RaAppBar-title": {
                    flex: 1,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                },
            }}
            {...props}
        >

            <img style={{maxWidth: "30px", marginRight: "16px"}} src={logo} alt="logo"  />
            <Typography
                variant="h6"
                color="inherit"
                id="react-admin-title"
            />
            <CurrentBusinessUnit data={data}  />

            <Box style={{position: "absolute", right: "8px" }}>
                <LocalesMenuButton
                    languages={[
                        { locale: 'hu', name: 'Magyar' },
                        { locale: 'en', name: 'English' },
                    ]}
                />
            </Box>
        </AppBar>
);
}
