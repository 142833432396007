import {Edit, SimpleForm, FormDataConsumer} from "react-admin";
import {UserDetails} from "./UserDetails";
import {userTransform} from "../../tools/userTransform";
import {PinkToolbar} from "../../components/PinkToolbar";
import React from "react";

export const UserEdit = () => {

    return (
        <Edit mutationMode="pessimistic" transform={userTransform} >
            <SimpleForm toolbar={<PinkToolbar alwaysEnableSave={true} deleteEnabled={false} />} mode={"all"}>
                <FormDataConsumer>
                    {({formData}) => (
                    <UserDetails edit={true} formData={formData}/>
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    )
}