import * as React from 'react';
import { useState } from 'react';
import {useLogin, useNotify, useTranslate} from 'react-admin';
import {Auth} from '@aws-amplify/auth';
import {Amplify} from '@aws-amplify/core'
import {Box, Button, Grid, IconButton, InputAdornment, Tab, Tabs, TextField, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {Visibility, VisibilityOff} from "@mui/icons-material";


Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_AWS_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEBCLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: process.env.REACT_APP_AWS_MANDATORY_SIGN_IN,
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const PposLoginPage = ({ theme }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [forEmail, setForEmail] = useState('');
    const [forCode, setForCode] = useState('');
    const [forNewPassword, setForNewPassword] = useState('');
    const [forNewPasswordAgain, setForNewPasswordAgain] = useState('');
    const [regEmail, setRegEmail] = useState('');
    const [regPassword, setRegPassword] = useState('');
    const [regPasswordAgain, setRegPasswordAgain] = useState('');
    const [regGivenName, setRegGivenName] = useState('');
    const [regFamilyName, setRegFamilyName] = useState('');
    const [regPhone, setRegPhone] = useState('');
    const [regPin, setRegPin] = useState('');
    const [verEmail, setVerEmail] = useState('');
    const [verCode, setVerCode] = useState('');
    const login = useLogin();
    const notify = useNotify();
    const translate = useTranslate();
    const [value, setValue] = React.useState(0);
    const [showPassword, setShowPassword] = useState({signIn: false, forgotPassword: false, register: false})
    const [showPasswordAgain, setShowPasswordAgain] = useState({signIn: false, forgotPasswordAgain: false, register: false})
    const passwordPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\.+,ß!@#$%^&*:;?_|~={}()<>`"/\'\[\\\\\\\]-])[A-Za-z\\d\.+,ß!@#$%^&*:;?_|~={}()<>`"/\'\[\\\\\\\]-]{8,}$'

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handlePinChange = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setRegPin(value);
    };

    const handleLogInSubmit = e => {
        e.preventDefault();
        // will call authProvider.login({ email, password })
        login({ email, password }).catch(err => {
            console.log(err);
            notify('signUp.notAuthorizedException', { type: 'error' });
        });
    };

    const handleSubmitForgot = e => {
        e.preventDefault();
        console.log(forEmail);
        Auth.forgotPassword(forEmail)
            .then(data => {
                console.log(data);
                notify('signUp.verificationCodeSent', { type: 'success' })
            })
            .catch(err => {
                console.log(err);
                if(err.message.includes('Invalid verification')){
                    notify('signUp.invalidVerificationCode', { type: 'error' })
                }
            });

    };

    const handleSubmitForgotSubmit = e => {
        e.preventDefault();
        console.log(forEmail);
        if(forNewPassword === forNewPasswordAgain){
        Auth.forgotPasswordSubmit(forEmail, forCode, forNewPassword)
            .then(data => {
                console.log(data);
                setValue(0);
                setForEmail('')
                setForCode('')
                setForNewPassword('')
                setForNewPasswordAgain('')
            })
            .catch(err => {
                console.log(err);
                if(err.message.includes('Invalid verification')){
                    notify('signUp.invalidVerificationCode', { type: 'error' })
                }
                if(err.message.includes('Password does not conform to policy', { type: 'error' })){
                    notify('signUp.passwordPolicy', { type: 'error' })
                }
                if(err.code==='ExpiredCodeException'){
                    notify('signUp.expiredVerificationCode', { type: 'error' })
                }
                if(err.code==='LimitExceededException'){
                    notify('signUp.limitExceededException', { type: 'error' })
                }
            });
        }else  {
            notify('signUp.passwordDifferentException', { type: 'error' })
        }
    };

    const handleSubmitRegistration = e => {
        e.preventDefault();
        let validation = true;
        if(regGivenName == null || regGivenName === ""){
            notify('signUp.givenNameEmpty', { type: 'error' })
            validation = false;
        }
        if(regFamilyName == null || regFamilyName === ""){
            notify('signUp.familyNameEmpty', { type: 'error' })
            validation = false;
        }
        if(regPassword !== regPasswordAgain){
            notify('signUp.passwordDifferentException', { type: 'error' })
            validation = false;
        }
        if(validation){
            Auth.signUp({
                username : regEmail,
                password : regPassword,
                attributes: {
                    given_name : regGivenName,
                    family_name : regFamilyName,
                    email : regEmail,
                    phone_number : regPhone,
                    'custom:pin' : regPin
                }
            }).catch(reason=>{
                if(reason.message.includes('Password cannot be empty')){
                    notify('signUp.passwordEmpty', { type: 'error' })
                }
                if(reason.message.includes('Password did not conform with policy')){
                    notify('signUp.passwordPolicy', { type: 'error' })
                }
                if(reason.message.includes('An account with the given email already exists.')){
                    notify('signUp.accountExist', { type: 'error' })
                }
                console.log(reason.message)
            }).then(response =>{
                console.log(response)
                if(response !== undefined) {
                    notify('signUp.successfulRegistration', {type: 'success'});
                    setValue(3);
                    setRegEmail('')
                    setRegPassword('')
                    setRegPasswordAgain('')
                    setRegFamilyName('')
                    setRegGivenName('')
                    setRegPhone('')
                    setRegPin('')
                }
            });
        }

    };

    const handleSubmitVerification = e => {
        e.preventDefault();
        console.log(regEmail);
        Auth.confirmSignUp(verEmail, verCode)
            .then(response =>{
               console.log(response);
                notify('signUp.successfulVerification', { type: 'success' });
                setValue(0);
                setVerEmail('')
                setVerCode('')
            })
            .catch(reason=>{
                if(reason.message.includes('Invalid code provided')){
                    notify('signUp.expiredVerificationCode', { type: 'error' })
                }
                if(reason.message.includes('Invalid verification')){
                    notify('signUp.invalidVerificationCode', { type: 'error' })
                }
                if(reason.message.includes('Invalid code provided, please request a code again.')){
                    notify('signUp.invalidCode', { type: 'error' })
                }
                console.log(reason.message)});
    };

    const handleClickShowSignInPassword = () => {
        setShowPassword({...showPassword, signIn:!showPassword.signIn})
    };
    const handleClickShowForgetPassword = () => {
        setShowPassword({...showPassword, forgotPassword:!showPassword.forgotPassword})
    };
    const handleClickShowForgetPasswordAgain = () => {
        setShowPasswordAgain({...showPasswordAgain, forgotPasswordAgain:!showPasswordAgain.forgotPasswordAgain})
    };

    const handleClickShowRegisterPassword = () => {
        setShowPassword({...showPassword, register:!showPassword.register})
    };

    const handleClickShowRegisterPasswordAgain = () => {
        setShowPasswordAgain({...showPasswordAgain, register:!showPasswordAgain.register})
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleMouseDownPasswordAgain = (event) => {
        event.preventDefault();
    };


    return (
        <div  align="center">
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                        <Tab label={translate('signUp.login')} {...a11yProps(0)} />
                        <Tab label={translate('signUp.forgotPassword')} {...a11yProps(1)} />
                        <Tab label={translate('signUp.registration')} {...a11yProps(2)} />
                        <Tab label={translate('signUp.verification')} {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <form onSubmit={handleLogInSubmit}>
                            <h2>{translate('signUp.login')}</h2>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={12}>
                                    <TextField required id="email" label={translate('signUp.email')} variant="filled" type="email" value={email} onChange={e => setEmail(e.target.value)}  style = {{width: 400}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required id="password" label={translate('signUp.password')}
                                               variant="filled" value={password}
                                               onChange={e => setPassword(e.target.value)}  style = {{width: 400}}
                                               type={showPassword.signIn ? 'text' : 'password'}
                                               InputProps={{
                                                   endAdornment: (
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={handleClickShowSignInPassword}
                                                               onMouseDown={handleMouseDownPassword}
                                                               edge="end"
                                                           >
                                                               {showPassword.signIn ? <VisibilityOff/> : <Visibility/>}
                                                           </IconButton>
                                                       </InputAdornment>
                                                   )
                                               }
                                               }

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type="submit" color="primary" style={{color: "white"}}>{translate('signUp.signIn')}</Button>
                                </Grid>
                            </Grid>
                    </form>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <form onSubmit={handleSubmitForgot}>
                        <h2>{translate('signUp.forgotPassword')}</h2>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <TextField required id="forEmail" label={translate('signUp.email')} variant="filled" type="email" value={forEmail} onChange={e => setForEmail(e.target.value)}  style = {{width: 400}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" type="submit" color="primary" style={{color: "white"}}>{translate('signUp.verificationCodeRequest')}</Button>
                            </Grid>
                        </Grid>
                    </form>
                    <form onSubmit={handleSubmitForgotSubmit}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <TextField required id="forCode" autoComplete={"off"} label={translate('signUp.verificationCode')} variant="filled" type="text" value={forCode} onChange={e => setForCode(e.target.value)}  style = {{width: 400}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField required id="forNewPassword" autoComplete={"new-password"} label={translate('signUp.newPassword')}
                                           variant="filled"   type={showPassword.forgotPassword ? 'text' : 'password'} value={forNewPassword}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position="end">
                                                       <IconButton
                                                           aria-label="toggle password visibility"
                                                           onClick={handleClickShowForgetPassword}
                                                           onMouseDown={handleMouseDownPassword}
                                                           edge="end"
                                                       >
                                                           {showPassword.forgotPassword ? <VisibilityOff/> :
                                                               <Visibility/>}
                                                       </IconButton>
                                                   </InputAdornment>
                                               ),
                                           }
                                           }
                                           inputProps={{ inputMode: 'text',
                                               pattern: passwordPattern }}
                                           helperText={translate('signUp.passwordHint')}
                                           onChange={e => setForNewPassword(e.target.value)}  style = {{width: 400}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField required id="forNewPasswordAgain" label={translate('signUp.newPasswordAgain')}
                                           variant="filled"   type={showPasswordAgain.forgotPasswordAgain ? 'text' : 'password'} value={forNewPasswordAgain}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position="end">
                                                       <IconButton
                                                           aria-label="toggle password visibility"
                                                           onClick={handleClickShowForgetPasswordAgain}
                                                           onMouseDown={handleMouseDownPasswordAgain}
                                                           edge="end"
                                                       >
                                                           {showPasswordAgain.forgotPasswordAgain ? <VisibilityOff/> :
                                                               <Visibility/>}
                                                       </IconButton>
                                                   </InputAdornment>
                                               ),
                                           }
                                           }
                                           inputProps={{ inputMode: 'text',
                                                pattern: passwordPattern }}
                                           onChange={e => setForNewPasswordAgain(e.target.value)}  style = {{width: 400}}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" type="submit" color="primary" style={{color: "white"}}>{translate('signUp.save')}</Button>
                            </Grid>
                        </Grid>
                    </form>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <form onSubmit={handleSubmitRegistration}>
                            <h2>{translate('signUp.registration')}</h2>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={12}>
                                    <TextField required id="regEmail" autoComplete={"off"} label={translate('signUp.email')} variant="filled" type="email" value={regEmail} onChange={e => setRegEmail(e.target.value)} style = {{width: 400}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required id="regPassword" autoComplete={"new-password"} label={translate('signUp.password')}
                                               variant="filled"  type={showPassword.register ? 'text' : 'password'}
                                               value={regPassword}
                                               InputProps={{
                                                   endAdornment: (
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={handleClickShowRegisterPassword}
                                                               onMouseDown={handleMouseDownPassword}
                                                               edge="end"
                                                           >
                                                               {showPassword.register ? <VisibilityOff/> :
                                                                   <Visibility/>}
                                                           </IconButton>
                                                       </InputAdornment>
                                                   ),
                                               }
                                               }
                                               inputProps={{ inputMode: 'text',
                                                   pattern: passwordPattern }}
                                               helperText={translate('signUp.passwordHint')}
                                               onChange={e => setRegPassword(e.target.value)} style = {{width: 400}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required id="regPasswordAgain" label={translate('signUp.newPasswordAgain')}
                                               variant="filled" type={showPasswordAgain.register ? 'text' : 'password'}
                                               value={regPasswordAgain}
                                               InputProps={{
                                                   endAdornment: (
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={handleClickShowRegisterPasswordAgain}
                                                               onMouseDown={handleMouseDownPasswordAgain}
                                                               edge="end"
                                                           >
                                                               {showPasswordAgain.register ? <VisibilityOff/> :
                                                                   <Visibility/>}
                                                           </IconButton>
                                                       </InputAdornment>
                                                   ),
                                               }
                                               }
                                               inputProps={{ inputMode: 'text',
                                                   pattern: passwordPattern }}
                                               onChange={e => setRegPasswordAgain(e.target.value)} style = {{width: 400}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required id="regGivenName" label={translate('signUp.givenName')} variant="filled" type="text" value={regGivenName} onChange={e => setRegGivenName(e.target.value)} style = {{width: 400}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required id="regFamilyName" label={translate('signUp.familyName')} variant="filled" type="text" value={regFamilyName} onChange={e => setRegFamilyName(e.target.value)} style = {{width: 400}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required id="regPhone" label={translate('signUp.phone')} variant="filled" type="text" value={regPhone} inputProps={{ inputMode: 'text', pattern: '\\+\\d{5,12}' }} helperText={translate('signUp.phoneHint')} onChange={e => setRegPhone(e.target.value)} style = {{width: 400}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required id="regPin" label={translate('signUp.pin')} variant="filled" type="text" value={regPin} inputProps={{ inputMode: 'numeric', pattern: '[0-9][0-9][0-9][0-9]', maxLength: 4, minLength: 4}} helperText={translate('signUp.pinHint')} onChange={handlePinChange} style = {{width: 400}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type="submit" color="primary" style={{color: "white"}}>{translate('signUp.signUp')}</Button>
                                </Grid>
                            </Grid>
                    </form>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <form onSubmit={handleSubmitVerification}>
                            <h2>{translate('signUp.verification')}</h2>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={12}>
                                    <TextField required id="verEmail" label={translate('signUp.email')} variant="filled" type="email" value={verEmail} onChange={e => setVerEmail(e.target.value)} style = {{width: 400}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required id="verCode" label={translate('signUp.verificationCode')} variant="filled" type="text" value={verCode} onChange={e => setVerCode(e.target.value)} style = {{width: 400}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type="submit" color="primary" style={{color: "white"}}>{translate('signUp.verification')}</Button>
                                </Grid>
                            </Grid>
                    </form>
                </TabPanel>
            </Box>
        </div>
    );
};

export default PposLoginPage;