import {
    List,
    SaveButton,
    TextField,
    TopToolbar,
    CreateButton,
    Filter,
    SelectInput,
    Datagrid,
    AutocompleteInput,
    useRecordContext, useTranslate
} from "react-admin";
import ConfigurableDatagrid from "../../components/ConfigurableDatagrid";
import React, {cloneElement} from "react";
import {Typography} from "@mui/material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const ListActions = ({
                         currentSort,
                         className,
                         resource,
                         filters,
                         displayedFilters,
                         exporter, // you can hide ExportButton if exporter = (null || false)
                         filterValues,
                         permanentFilter,
                         hasCreate, // you can hide CreateButton if hasCreate = false
                         basePath,
                         selectedIds,
                         onUnselectItems,
                         showFilter,
                         maxResults,
                         total,
                         ...rest
                     }) => {
    let unConfirmed = []

    return (
        <TopToolbar className={className}>
            <CreateButton basePath={basePath} label="technicalUsers.create"/>
        </TopToolbar>
    );
}

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

const UserFilters = [
            <AutocompleteInput source={"userType"} label={"technicalUsers.userTypeFilter"} choices={[
                {id: "TECHNICAL", name: "technicalUsers.technical"},
                {id: "REGULAR", name: "technicalUsers.registered"},
            ]} />
    ]

const UserList = () => {

    const UserTypeField = (props) => {
        const record = useRecordContext();
        const translate = useTranslate()
        if(record.isTechnical) {
            return (
                <div style={{display:"flex"}}>
                    <ManageAccountsIcon/>
                    <Typography> {translate("technicalUsers.technical")}</Typography>
                </div>
            )
        }else{
            return (
                <div style={{display:"flex"}}>
                    <VerifiedUserIcon />
                    <Typography>{translate("technicalUsers.registered")}</Typography>
                </div>
            )
        }
    }
    return (
        <List title={"technicalUsers.title"} filters={UserFilters}>
            <Datagrid rowClick={"edit"} bulkActionButtons={false}>
                <TextField source={'id'} label={'technicalUsers.id'} />
                <TextField source={'name'} label={'technicalUsers.name'} />
                <UserTypeField source={"isTechnical"} label={"technicalUsers.type"} />
            </Datagrid>
        </List>
    )
}

export default UserList