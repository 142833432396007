import {
    TextInput,
    FormDataConsumer,
    SimpleForm, required, ReferenceInput, AutocompleteInput
} from "react-admin";
import React from "react";
import {PinkToolbar} from "../../components/PinkToolbar";

export const BusinessUnitDetails = () => {
    return (
        <SimpleForm toolbar={<PinkToolbar deleteEnabled={false} />}>
            <ReferenceInput  source={"companyId"} reference={'company'}>
                <AutocompleteInput fullWidth={true} validate={required()}  optionText={"name"} label={"businessUnits.company"}
                />
            </ReferenceInput>
            <TextInput source="name" label={"businessUnits.name"} inputProps={{maxLength: 255}} validate={required()} />
            <TextInput source="zipCode" label={"businessUnits.zipCode"} type={"number"}  inputProps={{maxLength: 4}} validate={required()}/>
            <TextInput source="settlement" label={"businessUnits.settlement"} inputProps={{maxLength: 50}} validate={required()}/>
            <TextInput source="publicDomainName" label={"businessUnits.publicDomainName"} inputProps={{maxLength: 255}} validate={required()}/>
            <TextInput source="publicDomainType" label={"businessUnits.publicDomainType"} inputProps={{maxLength: 50}} validate={required()}/>
            <TextInput source="number" label={"businessUnits.number"} inputProps={{maxLength: 10}} validate={required()}/>
        </SimpleForm>
    )
}