import englishMessages from 'ra-language-english';
import hungarianMessages from "ra-language-hungarian";
import {mergeDeep} from "../tools/mergeDeep";

const data = {
    common: {
        columns: "Columns",
        columnSelected: "column selected"
    },
    products : {
        title: "Products",
        id: "ID",
        name: "Name",
        create: "Add new product",
        mainCategory: "NTAK Main Category",
        subCategory: "NTAK Subcategory",
        vatGroup: "VAT group",
        price: "Price",
        productUnits: "Product units",
        quantity: "Quantity",
        unit: "Unit",
        defaultPrice: "Default gross price",
        greaterThanZero: "Value must be greater than 0",
        empty: "No products to add",
        tags: "Tags",
        filterTags: "Filter tags",
        dialogTitle: "Confirm delete",
        dialogContextText: "Are you sure want to delete this tag? It will remove from all the products",
        yes: "Yes",
        no: "No"
    },
    priceListTemplate : {
        title: "Pricelists",
        id: "ID",
        name: "Unique name for the pirce list template",
        create: "Create new price list template",
        createTemplate: "Create template",
        created: "Pricelist created",
        createFail: "Pricelist create failed",
        generalData: "General data",
        managingProducts: "Adding / removing products",
        changePrices: "Change prices",
        addProductsToPriceList: "Add products to pricelist",
        noProductInPriceList: "Currently there aren't any products in the pricelist",
        edit: "Edit",
        delete: "Delete",
        saveAndActivate : "Save and activate",
        saveAsTemplate : "Save",
        saveAsInstance : "Pricelist save",
        successfulSave: "Saved Successfully",
        draft: "Draft",
        activated: "Activated",
        uniqueNameRequired: "Unique name required",
        state: "State",
        price: "Price",
        modify: "Edit",
        cancel: "Cancel",
        createPriceList: "Create new pricelist"
    },
    businessUnits : {
        title: "BusinessUnits",
        id: "ID",
        name: "Name",
        create: "Create a new business unit",
        publicDomainName: "Public domain name",
        publicDomainType: "Public domain type",
        number: "Number",
        settlement: "Settlement",
        zipCode: "Zip Code",
        address: "Address",
        empty: "No business units found",
        createdAt: "Created at",
        template: "Pricelist template",
        templates: "Pricelist templates"
    },
    technicalUsers : {
        title: "TUsers",
        id: "ID",
        name: "Name",
        create: "Add a new technical user",
        pinCode: "Pin code",
        pinCodeCreate: "Pin code (4 digits)",
        pinCodeAgain: "Pin code again (4 digits)",
        technicalUserData: "Technical user info",
        technicalUserRole: "User's roles",
        posSets: "Groups for the role",
        resetPIN: "Reset PIN",
        userTypeFilter: "User type filter",
        technical: "Technical user",
        registered: "Registered user",
        type: "User Type",
        validation :{
            pinDontMatch: "Pin Codes don't match"
        }
    },
    groups: {
        title: "Groups",
        cantDrag: "Can't be dragged to an element with a lower hierarchy",
        editNode: "Edit Group",
        addChild: "Add child",
        name: "Name",
        type: "Group type",
        pos: "POS",
        bar: "Bar",
        area: "Area",
        businessUnit: "BusinessUnit",
        editSuccessful : "Group has been successfully updated",
        deleteSuccessful : "Item has been deleted",
        poss: "POS-s",
        deleteUnsuccessful: "Group can't be deleted until it has a POS as a child",
        edit: "Click on the group to edit",
        expandAll: "Expand all groups",
        collapseAll: "Collapse all groups",
        add: "Add"
    },
    activePosSets: {
        title: "Active Business Unit",
        posSets: "Business Units",
        change: "Select active posSet",
        noActive: "You don't have an active business unit",
        setActivePosSet: "Select an Active Business Unit"
    },
    priceListToPosSets: {
        title: "Activate PriceList",
        priceLists: "Price Lists",
        priceList: "Price List",
        empty: "No price list found",
        currentPriceList : "Current pricelist",
        changePriceList: "Change price List",
        newPriceList: "New Price List",
        updated: "Price List updated"
    },
    device: {
        title: "Devices",
        empty: "No devices found",
        posId: "POS ID",
        posName: "POS Name",
        deviceId: "Device ID",
        deviceName: "Device Name",
        remove: "Remove from POS",
        shiftOpen: "Currently Has Open Shift",
        cantRemove: "Can't detach Device",
        detachAreYouSure: "Are you sure to detach this device",
        removeShort: "Detach",
    },
    pos: {
        title: "Create POS",
        add: "Create a POS",
        name: "POS name",
        edit: "Edit",
        cancel: "Cancel"
    },
    report: {
        add: "Create report",
        title: "Reports",
        type: "Report type",
        created: "Report successfully created",
        reset: "Reset",
        download: "Download",
        date:{
            start: "Start Date",
            end: "End Date"
        }
    },
    quickMenu: {
        title: "Quick Menu",
        add: "Add product to quck menu",
        addShort: "Add",
        success: "Product successfully added",
        full: "Quick menu is full, you can't add more products",
        error: "Error while adding a new product",
        addText: "To add product click in the table",
        choosePriceList: "Choose a pricelist, then you can edit it"
    },
    priceListsOfPos: {
        title: "Pricelists on Pos",
        id: "POS Id",
        name: "POS Name",
        priceListId: "Pricelist Id",
        priceListName: "Pricelisrt Name",
        priceListValidFrom: "ValidFrom",
        priceLists: "Pricelists",
        open: "Open",
        search : "Search",
        createdAt: "Created at"
    },
    company: {
        title: "Company",
        id: "ID",
        name: "Name",
        create: "Create a new company",
        publicDomainName: "Public Domain Name",
        publicDomainType: "Public Domain Type",
        number: "number",
        settlement: "Settlement",
        zipCode: "Zip Code",
        address: "Address",
        empty: "empty",
        taxNumber: "taxNumber",
        companyRegistrationNumber: "Company Registration Number",
        euTaxNumber: "EU Tax Number",
        contactName: "Contact Name",
        contactEmail: "Contact Email",
        contactPhone: "Contact Phone",
        phonePrefix: "Prefix",
        general: "General",
        contacts: "Contacts",
        created: "Company created successfully"
    },
    dashboard:{
        title: "Dashboard",
        activeWorkday: "Active Workday",
        dateRangeFilter: "Date range filter",
        date:{
            start: "Date range start",
            end: "Date Range end",
            endBeforeStartError: "End date is before start date"
        },
        refresh: "Refresh",
        filter: "Filter",
        sumPurchase: "Purchase Sum",
        sumTip: "Tip Sum",
        sumCanceled: "Storno Sum",
        total: "Total Sum",
        averageCartValue: "Average Cart Value",
        averageTip: "Average Tip",
        countPurchase: "Purchase Count",
        product: "TOP 10 Product",
        bar: "TOP 10 Bar",
        distributionRatio: "Distribution Ratio",
        cash: "Cash",
        helloPay: "Hellopay",
        otp: "Card",
        otp_manual: "Card (manual)",
        fiveMin: "5 min",
        fifteenMin: "15 min",
        oneHour: "1 hour",
        endBeforeStartError: "End date is before start date",
        moreThanOneDayInterval: "Interval must be less than 24 hours",
        endIsTooMuch: "End date is too much",
        trafficCount: "Traffic - Count",
        trafficSum: "Traffic - Sum",
        trxCount: "Transaction count",
        tip: "Tip",
        noWorkday: "No open workday found"
    },
    unit:{
        pieces: "pcs",
        huf: "HUF"
    }
}

const signUp  = {
    signUp : {
        login: "Login",
        forgotPassword: "Forgot password",
        registration: "Sign up",
        verification: "Verification",
        email: "Email",
        password: "Password",
        newPassword: "New password",
        newPasswordAgain: "New password again",
        passwordDifferentException: "Passwords are different",
        verificationCodeRequest: "Verification code request",
        save: "Save",
        givenName: "Given name",
        familyName: "Family name",
        phone: "Phone number",
        pin: "PIN",
        phoneHint: "Format: +xxxxxxxxxxx e.g.:+36201234567",
        pinHint: "It can only contain 4 digits",
        passwordHint: "Must contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character (^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + = )",
        verificationCode: "Verification code",
        verificationCodeSent: "Verification code sent. You can find the code in your email account.",
        signIn: "Login",
        signUp: "Sign up",
        notAuthorizedException: "Incorrect username or password.",
        passwordEmpty: "The password cannot be empty.",
        passwordPolicy: "The password is incorrect. Must be at least 8 long and contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character(^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + = ) ",
        accountExist: "There is already a user with this email address.",
        givenNameEmpty: "The given name cannot be empty.",
        familyNameEmpty: "The family name cannot be empty.",
        phoneNotValid: "Phone number format is incorrect. Correct format: +36xxxxxxxxx",
        pinEmpty: "PIN format is not correct. You can only enter numbers.",
        invalidVerificationCode: "Invalid verification code, please try again.",
        expiredVerificationCode: "Expired verification code, please try again.",
        invalidCode: "Invalid code provided, please request a code again.",
        limitExceededException: "Attempt limit exceeded, please try after some time.",
        successfulRegistration: "Successful registration. You will find a verification code in your email account.",
        successfulVerification: "Verification successful. You can now log in to the site."
    }
}

const engMessages = mergeDeep(englishMessages, data, signUp);


export default engMessages;