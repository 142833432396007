import {
    Button,
    ButtonGroup,
    Card, CardContent,
    IconButton,
    TextField,
    ToggleButton,
    ToggleButtonGroup, Typography
} from "@mui/material";
import {required, useTranslate, SimpleForm, useDataProvider, useNotify} from "react-admin";
import React, {useEffect, useState} from "react";
import RefreshIcon from '@mui/icons-material/Refresh';

import moment from "moment-timezone";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {AggregateCard} from "./AggregateCard";
import {DistributionRatioCard} from "./DistributionRatioCard";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import AddIcon from "@mui/icons-material/Add";
import {Top10Card} from "./Top10Card";
import {BarChartCard} from "./BarChartCard";
import cloneDeep from 'lodash/cloneDeep';

export const Dashboard = () => {
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const format = "YYYY.MM.DD HH:mm"
    const format2 = "HH:mm"
    const currentTime = moment();
    const refreshEverything = ["AGGREGATE", "TOP10", "COUNT_BY_INTERVAL", "SUM_BY_INTERVAL"]
    const [hasActiveWorkday, setHasActiveWorkday] = useState(undefined);
    const [data, setData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [countByInterval, setCountByInterval] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [sumByInterval, setSumByInterval] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [top10Data, setTop10Data] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const notify = useNotify();
    const [queryParams, setQueryParams] = useState({
        type: "activeWorkday",
        rangeStart: moment().subtract(1, 'day'),
        rangeEnd: moment(),
        counterInterval: 60,
        sumInterval: 60
    })

    const aggregateData = data.data ? data.data : []
    const countByIntervalData = countByInterval.data  ? countByInterval.data : []
    const sumByIntervalData = sumByInterval.data ? sumByInterval.data : []

    useEffect(() => {
        dataProvider.get('businessUnit/hasOpenWorkday').then(value => {
            if(!value.data) {
                notify("dashboard.noWorkday", {type: "warning"})
                setQueryParams({...queryParams, type: "dateRangeFilter"})
                setHasActiveWorkday(false)
            }else{
                setHasActiveWorkday(true)
            }
        })

    }, [])

    const handleRefresh = (toBeRefreshed) => {
        if(toBeRefreshed.includes("AGGREGATE")) {
            setData({...data, loaded: false})
        }
        if(toBeRefreshed.includes("TOP10")) {
            setTop10Data({...top10Data, loaded: false})
        }
        if(toBeRefreshed.includes("COUNT_BY_INTERVAL")) {
            setCountByInterval({...countByInterval, loaded: false})
        }
        if(toBeRefreshed.includes("SUM_BY_INTERVAL")) {
            setSumByInterval({...sumByInterval, loaded: false})
        }
    }

    const handleFilter = () => {
        const rangeStart = cloneDeep(queryParams.rangeStart)
        if(queryParams.rangeEnd < queryParams.rangeStart){
            notify("dashboard.endBeforeStartError", {type: "error"})
            return
        }
        if(rangeStart.add(1, 'days').add(1, "seconds") < queryParams.rangeEnd){
            notify("dashboard.moreThanOneDayInterval", {type: "error"})
            return
        }
        if(queryParams.rangeEnd > currentTime )
        {
            notify("dashboard.endIsTooMuch", {type: "error"})
            return
        }

        handleRefresh(refreshEverything)
    }


    const handleToggle = (event, newAlignment) => {
        if(hasActiveWorkday) {
            setQueryParams({...queryParams, type: newAlignment});
        }else{
            notify("dashboard.noWorkday", {type: "warning"})
        }
    };

    useEffect(() => {
        if (!data.loading && !data.loaded && hasActiveWorkday !== undefined) {
            const workday = queryParams.type === "activeWorkday" && hasActiveWorkday
            const startPeriod = queryParams.type === "dateRangeFilter" || hasActiveWorkday === false ? queryParams.rangeStart.toISOString() : null
            const endPeriod = queryParams.type === "dateRangeFilter" || hasActiveWorkday === false ? queryParams.rangeEnd.toISOString() : null
            setData({...data, loading: true});
            dataProvider.get('dashboard/aggregates', {workday: workday, startPeriod: startPeriod, endPeriod: endPeriod})
                .then(value => {
                    const aggregate = value.data
                    setData({loading: false, loaded: true, data: aggregate, error: undefined});
                })
                .catch(reason => {
                    setData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [data.loading, data.loaded, hasActiveWorkday]);

    useEffect(() => {
        if (!top10Data.loading && !top10Data.loaded && hasActiveWorkday !== undefined) {
            const workday = queryParams.type === "activeWorkday" && hasActiveWorkday
            const startPeriod =  queryParams.type === "dateRangeFilter" ||  hasActiveWorkday === false  ? queryParams.rangeStart.toISOString() : null
            const endPeriod = queryParams.type === "dateRangeFilter" ||  hasActiveWorkday === false  ? queryParams.rangeEnd.toISOString() : null
            setTop10Data({...top10Data, loading: true})
            dataProvider.get('dashboard/top10', {workday: workday, startPeriod: startPeriod, endPeriod: endPeriod})
                .then(value => {
                    setTop10Data({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setTop10Data({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [top10Data.loading, top10Data.loaded, hasActiveWorkday]);

    useEffect(() => {
        if (!countByInterval.loading && !countByInterval.loaded && hasActiveWorkday !== undefined) {
            const workday = queryParams.type === "activeWorkday" && hasActiveWorkday
            const startPeriod =  queryParams.type === "dateRangeFilter" ||  hasActiveWorkday === false  ? queryParams.rangeStart.toISOString() : null
            const endPeriod = queryParams.type === "dateRangeFilter" ||  hasActiveWorkday === false  ?  queryParams.rangeEnd.toISOString() : null
            setCountByInterval({...countByInterval, loading: true})
            dataProvider.get('dashboard/countByTimeInterval', {workday: workday, startPeriod: startPeriod, endPeriod: endPeriod, groupingIntervalInMinutes: queryParams.counterInterval })
                .then(value => {
                    const formattedData = value.data.map(it => {return {trxCount: it.trxCount, date: moment(new Date(it.intervalStart)).format(format2)}})
                    setCountByInterval({loading: false, loaded: true, data: formattedData, error: undefined});
                })
                .catch(reason => {
                    setCountByInterval({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [countByInterval.loading, countByInterval.loaded, hasActiveWorkday]);

    useEffect(() => {
        if (!sumByInterval.loading && !sumByInterval.loaded && hasActiveWorkday !== undefined) {
            const workday = queryParams.type === "activeWorkday" && hasActiveWorkday
            const startPeriod =  queryParams.type === "dateRangeFilter" || hasActiveWorkday === false  ? queryParams.rangeStart.toISOString() : null
            const endPeriod = queryParams.type === "dateRangeFilter" ||  hasActiveWorkday === false  ?  queryParams.rangeEnd.toISOString() : null
            setSumByInterval({...sumByInterval, loading: true})
            dataProvider.get('dashboard/sumAmountByTimeInterval', {workday: workday, startPeriod: startPeriod, endPeriod: endPeriod, groupingIntervalInMinutes:queryParams.sumInterval })
                .then(value => {
                    const formattedData = value.data.map(it => {return {total: it.totalMinusTip, tip: it.tip,  date: moment(new Date(it.intervalStart)).format(format2)}})
                    setSumByInterval({loading: false, loaded: true, data: formattedData, error: undefined});
                })
                .catch(reason => {
                    setSumByInterval({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [sumByInterval.loading, sumByInterval.loaded, hasActiveWorkday]);


    return (
        <div style={{display: "flex", margin: "1rem", flexDirection: "column"}}>
            <div style={{display: "flex", marginBottom: "3rem"}}>
                <Button disabled={queryParams.type !== "activeWorkday"} size={"large"}  onClick={e => {handleRefresh(refreshEverything) }}>
                     {translate('dashboard.refresh')}  <RefreshIcon />
                </Button>
            <ToggleButtonGroup
                color="primary"
                value={queryParams.type}
                exclusive
                onChange={handleToggle}
                style={{marginLeft: "1rem", marginRight: "1rem"}}
            >
                <ToggleButton size={"large"} disabled={!hasActiveWorkday} value="activeWorkday">{translate("dashboard.activeWorkday")}</ToggleButton>
                <ToggleButton size={"large"}
                              value="dateRangeFilter">{translate("dashboard.dateRangeFilter")}</ToggleButton>
            </ToggleButtonGroup>

            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                    renderInput={(props) => {
                        return (
                            <TextField size={"large"} variant={"filled"} {...props} />
                        )
                    }
                    }
                    label={translate("dashboard.date.start")}
                    inputFormat={format}
                    value={queryParams.rangeStart}
                    disabled={queryParams.type !== "dateRangeFilter"}
                    maxDate={queryParams.rangeEnd}
                    onChange={(newValue) => {
                        setQueryParams({...queryParams, rangeStart: newValue});
                    }}
                />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                    renderInput={(props) => {
                        return (

                            <TextField   style={{marginLeft: "1rem", marginRight: "1rem"}} size={"large"} variant={"filled"} {...props} />
                        )
                    }
                    }
                    label={translate("dashboard.date.end")}
                    inputFormat={format}
                    disabled={queryParams.type !== "dateRangeFilter"}
                    value={queryParams.rangeEnd}
                    minDate={queryParams.rangeStart}
                    maxDate={currentTime}
                    onChange={(newValue) => {
                        setQueryParams({...queryParams, rangeEnd: newValue});
                    }}
                />
            </LocalizationProvider>
                <Button disabled={queryParams.type !== "dateRangeFilter"} size={"large"} onClick={handleFilter}>
                    {translate('dashboard.filter')} <FilterAltIcon />
                </Button>
            </div>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                <AggregateCard title={translate("dashboard.sumPurchase")} value={aggregateData.sumPurchase} unit={translate("unit.huf")} />
                <AggregateCard title={translate("dashboard.sumTip")} value={aggregateData.sumTip} unit={translate("unit.huf")} />
                <AggregateCard title={translate("dashboard.sumCanceled")} value={aggregateData.sumCanceled} unit={translate("unit.huf")} />
                <AggregateCard title={translate("dashboard.total")} value={aggregateData.total} unit={translate("unit.huf")} />
                <AggregateCard title={translate("dashboard.averageCartValue")} value={aggregateData.averageCartValue} unit={translate("unit.huf")} />
                <AggregateCard title={translate("dashboard.averageTip")} value={aggregateData.averageTip} unit={translate("unit.huf")} />
                <AggregateCard title={translate("dashboard.countPurchase")} value={aggregateData.countPurchase} unit={translate("unit.pieces")} />
            </div>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                <Top10Card title={translate("dashboard.product")} value={(top10Data.data === undefined ? [] : top10Data.data.topProducts)} unit={translate("unit.huf")}/>
                <Top10Card title={translate("dashboard.bar")} value={(top10Data.data === undefined ? [] : top10Data.data.topBars)} unit={translate("unit.huf")}/>
            </div>
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                <DistributionRatioCard data={aggregateData} />
                {countByIntervalData ?
                    <BarChartCard data={countByIntervalData} title={translate("dashboard.trafficCount")}
                                  xAxisKey={"date"} queryParams={queryParams} setQueryParams={setQueryParams} bar1Name={"trxCount"} type={"COUNT"} refresh={handleRefresh}/>
                    :
                    null
                }

                {sumByIntervalData ?
                    <BarChartCard data={sumByIntervalData} title={translate("dashboard.trafficSum")}
                                  xAxisKey={"date"} queryParams={queryParams} setQueryParams={setQueryParams} bar1Name={"total"} bar2Name={"tip"} type={"SUM"}  refresh={handleRefresh}/>
                    :
                    null
                }
            </div>
        </div>
    )
}