import {FormTab, required, TextInput, TabbedForm, AutocompleteInput, minValue, maxValue} from "react-admin"
import React from "react";
import {countyCodes} from "../../components/CountyCodes";
import {TaxNumberInput} from "../../components/TaxNumberInput";
import {CompanyRegistrationNumberInput} from "../../components/CompanyRegistationNumberInput";
import {PinkToolbar} from "../../components/PinkToolbar";
export const CompanyDetails = () => {
    return (
        <TabbedForm toolbar={<PinkToolbar deleteEnabled={false} />} mode="onBlur" reValidateMode="onBlur">
        <FormTab label="company.general">
            <TextInput source="name" inputProps={{maxLength: 255}}  label={"company.name"} />
            <TaxNumberInput source="taxNumber" label={'company.taxNumber'}  />
            <TextInput source="euTaxNumber" label={'company.euTaxNumber'} inputProps={{maxLength: 15}}  />
            <CompanyRegistrationNumberInput source="companyRegistrationNumber" label={'company.companyRegistrationNumber'}  />
        </FormTab>
        <FormTab label={"company.address"}>
            <TextInput source="zipCode" label={"company.zipCode"} type={"number"}  inputProps={{maxLength: 4}} validate={[required(), minValue(1000), maxValue(9999)]}/>
            <TextInput source="settlement" label={"company.settlement"} inputProps={{maxLength: 50}} validate={required()}/>
            <TextInput source="publicDomainName" label={"company.publicDomainName"} inputProps={{maxLength: 255}} validate={required()}/>
            <TextInput source="publicDomainType" label={"company.publicDomainType"} inputProps={{maxLength: 50}} validate={required()}/>
            <TextInput source="number" label={"company.number"} inputProps={{maxLength: 10}} validate={required()}/>
        </FormTab>
            <FormTab label={"company.contacts"}>
                <TextInput source="contactName"  validate={required()}  inputProps={{maxLength: 255}} label={'company.contactName'} />
                <TextInput source="contactEmail" validate={required()}  inputProps={{maxLength: 255}} label={'company.contactEmail'} />
                <div style={{display: "flex"}}>
                    <AutocompleteInput validate={required()} style={{marginRight: "8px"}} label={'company.phonePrefix'} source={"prefix"} choices={countyCodes} optionValue={"dial_code"}
                                       optionText={((record)=> {
                                           return `${record.dial_code} (${record.code})`
                                       })} defaultValue={"+36"} />
                    <TextInput source="contactPhone" validate={required()}  inputProps={{maxLength: 20}} label={'company.contactPhone'} />
                </div>
            </FormTab>
        </TabbedForm>
    )
}