import {Card, Typography} from "@mui/material";
import {Cell, Pie, PieChart, Sector} from "recharts";
import React, {useState} from "react";
import {useTranslate} from "react-admin";

export const DistributionRatioCard = ({data}) => {
    const translate = useTranslate()
    const [activeIndex, setActiveIndex] = useState(0)

    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    };
    const COLORS = ['#00C49F', '#e64614', '#ff71bc'];

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {translate(`dashboard.${payload.name}`)}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value} Ft`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`${(percent * 100).toFixed(2)}%`}
                </text>
            </g>
        );
    };
    return (
        <Card sx={{ width: "450px", height: "300px", marginRight: "2rem", marginBottom: "2rem"}}>
            <Typography sx={{marginLeft: "1rem", marginTop: "1rem", fontSize: 14 }} color="text.secondary" gutterBottom>
                {translate("dashboard.distributionRatio")}
            </Typography>
            <PieChart width={450} height={250}>
                <Pie data={data.distributionRatio} dataKey="amount" cx="50%" cy="50%" activeIndex={activeIndex} activeShape={renderActiveShape} onMouseEnter={onPieEnter} innerRadius={60} outerRadius={70} fill="#82ca9d">
                    {data.distributionRatio ? data.distributionRatio.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))
                        :
                        null
                    }
                </Pie>
            </PieChart>
        </Card>
    )
}