import React from 'react';
import ProductList from "./ProductList";
import ProductCreate from "./ProductCreate";
import ProductEdit from "./ProductEdit";
import FastfoodIcon from '@mui/icons-material/Fastfood';

const Products = {
    list: ProductList,
    create: ProductCreate,
    edit: ProductEdit,
    icon: FastfoodIcon

}

export default Products