export const userTransform = (data) => {
    if(data.roles.length === 0){
        data.updatedRoles = []
    }else {
        for (let i = 0; i < data.roles.length; i++){
            if(typeof data.roles[i].id == 'number' && data.roles[i].id !== data.updatedRoles[i].id){
                data.updatedRoles[i].id = data.roles[i].id
            }
        }
    }
    return data;
};