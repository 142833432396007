import React, {useEffect, useState} from "react";
import {useDataProvider, useTranslate, TextInput} from "react-admin";
import {useFormContext} from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import {uuidv4} from "../../tools/generate";
import {PosListDataGrid} from "./PosDatagrid";
import {Button} from "@mui/material";

export const PosList = ({formData}) => {
    const MAX_NUMBER_OF_POS = 1000
    const translate = useTranslate()
    const { setValue } = useFormContext();
    const [data, setData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const dataProvider = useDataProvider()
    useEffect(() => {
        if (!data.loading &&  formData.businessUnitId) {
            setData({...data, loading: true});
            dataProvider.get(`posSet/posList/${formData.businessUnitId}`)
                .then(value => {
                    setData({loading: false, loaded: true, data: value.data, error: undefined});
                    setValue('posSets', value.data)
                })
                .catch(reason => {
                    setData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [formData.businessUnitId]);

    const addPriceList = () => {
        const posSetNames = formData.posSets.map(it => it.name)
        let generatedName = ``
        let uniqueNameFound = false
        for(let i = 1; i < MAX_NUMBER_OF_POS && !uniqueNameFound; i++) {
            generatedName = `POS - ${formData.posSets.length + i}`
            if (!posSetNames.includes(generatedName)) {
                uniqueNameFound = true
            }
        }
        setValue('posSets', [...formData.posSets, {id: uuidv4(), name: generatedName}])
    };
        return (
            <>
            <Button style={{marginBottom: "16px", color: "white"}} disabled={!formData.businessUnitId} variant={"contained"} color={"primary"} onClick={addPriceList} >
                <AddIcon />   {translate('pos.add')}
            </Button>
                {formData.posSets && formData.posSets.length > 0 ?
                    <PosListDataGrid rows={formData.posSets}/>
                    :
                    null
                }
            </>
        )

}