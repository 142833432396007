import React, {useState} from 'react';
import {
    TextField,
    List,
    ArrayField,
    SingleFieldList,
    ChipField,
    ReferenceField,
    AutocompleteArrayInput,
    FilterForm,
    useDataProvider,
    useRefresh, useTranslate,
} from "react-admin";
import ConfigurableDatagrid from "../../components/ConfigurableDatagrid";
import Dialog from '@mui/material/Dialog';
import {getValidPrivilegesIds} from "../../components/authentication/PermissionHandler";
import {useEffect} from "react";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ClearIcon from '@mui/icons-material/Clear';
import {Button, DialogTitle} from "@mui/material";


const ProductList = ({permissions}) => {
    const dataProvider = useDataProvider()
    const hasEdit = getValidPrivilegesIds("PRODUCT", "EDIT").some(r => permissions.includes(r)) ? "edit" : undefined
    const [tag, setTag] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [selected, setSelected] = React.useState([])
    const refresh = useRefresh()
    const [open, setOpen] = useState(false)
    const [deleteTagId, setDeleteTagId] = useState(null)
    const translate = useTranslate()

    useEffect(() => {
        if (!tag.loading && !tag.loaded) {
            setTag({...tag, loading: true});
            dataProvider.get(`businessUnit/tag`)
                .then(value => {
                    setTag({loading: false, loaded: true, data: value.data, error: undefined});
                })
                .catch(reason => {
                    setTag({loading: false, loaded: true, data: [], error: reason});
                })
        }
    }, [tag.loading, tag.loaded]);

    const handleDialogClose = toDelete => {
        setOpen(false)
        if(toDelete && deleteTagId != null){
            dataProvider.delete(`product/tag`, {id: deleteTagId})
                .finally(() => {
                    setTag({...tag, loaded: false})
                    refresh()
                });
        }
        setDeleteTagId(null)
    }

    const handleDialogOpen = () => {
        setOpen(true)
    }

const handleDeleteButton = (removedId) => e => {
    handleDialogOpen()
    e.stopPropagation()
    setDeleteTagId(removedId)
}


const optionRenderer = choice => {
    return <span>
        {choice.name}
    {!(selected.some(e => e === choice.id)) && (<ClearIcon className={"notDraggable"}
                                                           onClick={handleDeleteButton(`${choice.id}`)}
                                                           fontSize={"small"}
                                                           sx={{marginBottom:"0px", color:"red", position: "absolute", right: "5px"}}
                                                />)}
    </span>
}


const postFilter = [
    <AutocompleteArrayInput label={'products.filterTags'} onChange={(e) => setSelected(e)} source="tags" choices={tag.data} alwaysOn sx={{width: "300px", position: "absolute", right: "10px", top: "50px"}} optionText={optionRenderer} />
];

    return (
    <>
        <Dialog open={open} onClose={handleDialogClose} >
            <DialogTitle>{translate("products.dialogTitle")}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {translate("products.dialogContextText")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleDialogClose(false)}>{translate("products.no")}</Button>
                <Button onClick={() => handleDialogClose(true)}>{translate("products.yes")}</Button>
            </DialogActions>
        </Dialog>
        <List title={"products.title"}>
            <FilterForm filters={postFilter} />
            <ConfigurableDatagrid rowClick={hasEdit} bulkActionButtons={false}>
                <TextField source="id" label={'products.id'}/>
                <TextField source="name" label={'products.name'}/>
                <ReferenceField label={'products.mainCategory'}  reference={"product/mainCategory"} link={false} source={"mainCategoryId"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'products.subCategory'} reference={"product/subCategory"} link={false} source={"subCategoryId"}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'products.vatGroup'} reference={"product/vatGroup"} link={false} source={"vatPercentage"} >
                    <TextField source="name" />
                </ReferenceField>
                <ArrayField source={"units"} label={'products.productUnits'} sortable={false}>
                    <SingleFieldList linkType={false}>
                        <ChipField source={"quantityAndUnit"}/>
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source={"tags"} label={'products.tags'} sortable={false}>
                    <SingleFieldList linkType={false}>
                        <ChipField source={"name"}/>
                    </SingleFieldList>
                </ArrayField>
            </ConfigurableDatagrid>
        </List>
    </>
    )
}

export default ProductList