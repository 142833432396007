import {
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText, Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    Typography
} from "@mui/material";
import React from "react";

export const Top10Card = ({title, value, unit}) => {

    return(
        <Card sx={{ width: "36rem", marginRight: "2rem", marginBottom: "2rem"}}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {title}
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                        <TableBody>
                            { value!== undefined ? value.map
                            ((element, index) =>
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index+1}
                                    </TableCell>
                                    <TableCell align="left">{element.name}</TableCell>
                                    <TableCell align="right">{element.sum+ ' '+ unit}</TableCell>
                                </TableRow>
                            )
                                : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}