import EditIcon from "@mui/icons-material/Edit";
import React, {useState} from "react";
import {Typography} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from "moment-timezone";
import {Menu, MenuBook} from "@mui/icons-material";

export const Title = ({title, currentPriceListName, createdAt}) => {
    const format = "YYYY.MM.DD HH:mm"
    const date = createdAt ? moment(new Date(createdAt)).format(format) : ""
    const [displayIcon, setDisplayIcon] = useState(false)
    const mouseEnter = () => {
        setDisplayIcon(true)
    }
    const mouseLeave = () => {
        setDisplayIcon(false)
    }

    return (
        <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <Typography width={"18rem"} >{title}</Typography>
                {currentPriceListName ?
                    <>
                        <div style={{display: "flex", width: "18rem"}}>
                            <Menu/><Typography>{currentPriceListName}</Typography>
                        </div>
                        <div style={{display: "flex"}}><AccessTimeIcon/> <Typography>{date}</Typography></div>
                    </>
                    :
                    null
                }
                {displayIcon ?
                    <EditIcon color={"action"} />
                    :
                    <EditIcon style={{color: "#fff"}}/>
                }
            </div>


        </div>
    )
}