import {Auth} from '@aws-amplify/auth';
import {Amplify} from '@aws-amplify/core'
import dataProvider from "../dataProvider";

Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_AWS_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEBCLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: process.env.REACT_APP_AWS_MANDATORY_SIGN_IN,

    }
});
function getMe() {
    return new Promise((resolve, reject) => {
        dataProvider.get("auth/me").then(
        value => {
            localStorage.setItem("activePosSetId", value.data.activePosSetId)
            localStorage.setItem('permission', JSON.stringify( value.data.privileges.map(it => it.id)))
            localStorage.setItem('businessUnits', JSON.stringify(value.data.businessUnits))
            localStorage.setItem('companies', JSON.stringify(value.data.companies))
            resolve(value.data.privileges.map(it => it.id));
        }
    ).catch(reason=>{
            reject(reason);
        })

    }
    )
}

const authProvider = {
    login: ({ email, password }) => {
        return new Promise((resolve, reject) => {
            Auth.signIn(email, password).then(r =>
                Auth.currentSession().then(res => {
                    let accessToken = res.getAccessToken()
                    let jwt = accessToken.getJwtToken()
                    localStorage.setItem('username', email);
                    localStorage.setItem('token', jwt)
                    resolve(res);
                }).catch(reason=>{
                    reject(reason);
                })
            ).catch(reason=>{
                reject(reason);
            });
        });

    },
    logout: () => {
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.removeItem('permission');
        localStorage.removeItem('activePosSetId');
        localStorage.removeItem('businessUnits');
        localStorage.removeItem('companies');
        return Promise.resolve();
    },

    checkAuth: () => {
        return new Promise((resolve, reject) => {
            Auth.currentSession()
                .then(data => {
                    localStorage.setItem('token', data.getAccessToken().getJwtToken());
                    resolve();
                })
                .catch(err => {
                    console.log(err)
                    reject();
                });
        })
    },

    checkError:  (error) => {
        const status = error.status;
        if (status === 401 /*|| status === 403*/) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: localStorage.getItem('username'),
        }),
    getPermissions: async () => {
        console.log("getpermission", new Date())
        await getMe()
        return Promise.resolve(JSON.parse(localStorage.getItem('permission')))
    },

};

export default authProvider;
