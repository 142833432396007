import {Card, CardContent, Typography} from "@mui/material";
import React from "react";

export const AggregateCard = ({title, value, unit}) => {
    return(
        <Card sx={{ width: "12rem", marginRight: "2rem", marginBottom: "2rem"}}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="h5" component="div">
                    {value} {unit}
                </Typography>
            </CardContent>
        </Card>
    )
}