import React from 'react';
import {Create, SimpleForm} from 'react-admin';
import ProductDetails from "./ProductDetails";
import {PinkToolbar} from "../../components/PinkToolbar";

const ProductCreate = (props) => {
    return (
        <Create {...props} redirect={"list"} title={'products.create'}>
            <SimpleForm toolbar={<PinkToolbar deleteEnabled={false}  />}>
              <ProductDetails />
            </SimpleForm>
        </Create>
    )
}

export default ProductCreate