import React, {useEffect, useRef, useState} from "react";
import Tree from "rc-tree";

import 'rc-tree/assets/index.css'
import './draggable.css'
import {Business, CreditCard, ExpandLess, ExpandMore, LocalBar, Save, Storefront} from "@mui/icons-material";
import {
    useNotify,
    SimpleForm,
    useTranslate,
    FormDataConsumer,
    TextInput,
    required,
    AutocompleteInput,
    Create, useDataProvider,
} from "react-admin";
import {
    Button,
    Dialog,
    DialogTitle,
    FormLabel,
    LinearProgress,
    Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useFormContext} from "react-hook-form";
import AddIcon from '@mui/icons-material/Add';
import {AutocompleteSimpleInput} from "../../components/AutocompleteSimpleImput";
import {uuidv4} from "../../tools/generate";
import {PinkToolbar} from "../../components/PinkToolbar";
import EditIcon from '@mui/icons-material/Edit';
import {groupTransform, traverse_tree} from "../../tools/groupTransform";
import {Title} from "../../components/Title";
import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldMoreDouble';
import UnfoldLessDoubleIcon from '@mui/icons-material/UnfoldLessDouble';

const groupTypes = [
    {id: '2', name: 'groups.bar'},
    {id: '3', name: 'groups.area'},
    {id: '4', name: 'groups.businessUnit'},
]


const SwitcherIcon = (obj) => {
    if (obj.isLeaf) {
        return (<div/>)
    }
    if (obj.expanded) {
        return (<div style={{backgroundColor: "#fafafb"}}><ExpandLess/></div>)
    } else {
        return (<div style={{backgroundColor: "#fafafb"}}><ExpandMore/></div>)
    }
}

const Icon = (obj) => {
    if (obj.data.level) {
        switch (obj.data.level) {
            case 1:
                return (<div><CreditCard/></div>)
            case 2:
                return (<LocalBar/>)
            case 3:
                return (<Storefront/>)
            case 4:
                return (<Business/>)
        }
    } else {
        return <div/>
    }
}


const Group = () => {
    const notify = useNotify()
    const translate = useTranslate()
    const dataProvider = useDataProvider()
    const [data, setData] = React.useState({loading: false, loaded: false, data: [], error: undefined});
    const [gData, setGData] = useState([])
    const [autoExpandParent, setAutoExpandParent] = useState(true)
    const [expandedKeys, setExpandedKeys] = useState([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [editNode, setEditNode] = useState({})
    const [addChild, setAddChild] = useState(false)
    const [expandableIds, setExpandableIds] = useState([])
    const [expanded, setExpanded] = useState(false)
    const [filteredGroupTypes, setFilteredGroupTypes] = React.useState(groupTypes);
    const {setValue} = useFormContext();

    useEffect(() => {
        if (!data.loading && !data.loaded) {
            setData({...data, loading: true});
            dataProvider.get('group')
                .then(value => {
                    setData({loading: false, loaded: true, data: value.data, error: undefined});
                    traverse_tree(value.data, setExpandableIds)
                    setGData([value.data])
                    setValue("tree", value.data)
                })
                .catch(reason => {
                    setData({loading: false, loaded: true, data: undefined, error: reason});
                })
        }
    }, [data.loading, data.loaded]);



    const handleDialogClose = () => {
        setDialogOpen(false)
        setFilteredGroupTypes(groupTypes)
    }

    const onDragStart = info => {
        console.log('start', info);
    };

    const handleAddChild = () => {
        setAddChild(true)
    };

    const handleExpandAll = () => {
        setExpanded(true)
        setExpandedKeys(expandableIds)
    };

    const handleCollapseAll = () => {
        setExpanded(false)
        setExpandedKeys([])
    };

    const onDrop = (info) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        if (((info.dragNode.level < info.node.level && info.node.level < 4) || // a higher level element can't ba child of a lower level element
                (info.dragNode.level == info.node.level && dropPosition === 1)) || //same level elemnts can ce reordered
            (info.node.level === 4 && dropPosition != -1)) { // there must be a singe root node

            const loop = (data, key, callback) => {
                data.forEach((item, index, arr) => {
                    if (item.key === key) {
                        callback(item, index, arr);
                        return;
                    }
                    if (item.children) {
                        loop(item.children, key, callback);
                    }
                });
            };
            const data = [...gData];

            // Find dragObject
            let dragObj;
            loop(data, dragKey, (item, index, arr) => {
                arr.splice(index, 1);
                dragObj = item;
            });
            if(!expandableIds.includes(dropKey)){
                setExpandableIds([...expandableIds, dropKey])
                setExpandedKeys([...expandedKeys, dropKey])
            }
            if (dropPosition === 0) {
                // Drop on the content
                loop(data, dropKey, item => {
                    // eslint-disable-next-line no-param-reassign
                    item.children = item.children || [];
                    // where to insert
                    item.children.unshift(dragObj);
                });
            } else {
                // Drop on the gap (insert before or insert after)
                let ar;
                let i;
                loop(data, dropKey, (item, index, arr) => {
                    ar = arr;
                    i = index;
                });
                if (dropPosition === -1) {
                    ar.splice(i, 0, dragObj);
                } else {
                    ar.splice(i + 1, 0, dragObj);
                }
            }
            setGData(data)
            setValue("tree", data[0])
        } else {
            notify("groups.cantDrag", { type: 'error' })
        }
    };

    const onExpand = (expandedKeys) => {
        if(expandedKeys.length === expandableIds.length){
            setExpanded(true)
        }

        if(expandedKeys.length === 0){
            setExpanded(false)
        }

        setExpandedKeys(expandedKeys)
        setAutoExpandParent(false)
    };

    const onSelect = (selectedKeys, e) => {
        setEditNode(e.node)
        setFilteredGroupTypes(filteredGroupTypes.filter(it => it.id < e.node.level))
        setValue("parentName", e.node.name)
        setValue("childName", null)
        setValue("groupType", null)
        setAddChild(false)
        setDialogOpen(true)
    }

    const handleEditNode = (e, formData) => {
        //to fix double click error
        if(e.detail === 1) {
            const tree = gData[0]
            addChildren(tree, editNode.key, formData.parentName, formData.groupType, formData.childName, formData.childId)
            setGData([tree])
            setValue("tree", tree)

            handleDialogClose()
            notify("groups.editSuccessful", {type: 'success'})
            setFilteredGroupTypes(groupTypes)
        }
    }
    const handleDelete = () => {
        const tree = gData[0]
        removeItem(tree, editNode.key)
        setGData([tree])
        setValue("tree", tree)
        handleDialogClose()
    }

    const addChildren = (element, selected, title, level, childTitle, childId) => {
        if (element.key == selected) {
            if (title) {
                element.title = <Title title={title} />
                element.name = title
            }
            if (level && childTitle) {
                const key = uuidv4()
                if (element.children && element.children.length > 0) {
                    element.children.push({
                        uuid: key,
                        key: key,
                        title: <Title title={childTitle} />,
                        level: parseInt(level),
                        posId: childId,
                        name: childTitle
                    })
                } else {
                    element.children = [{
                        uuid: key,
                        key: key,
                        title: <Title title={childTitle} />,
                        level: parseInt(level),
                        posId: childId,
                        name: childTitle
                    }]
                    setExpandedKeys([...expandedKeys, element.key])
                    setExpandableIds([...expandableIds, element.key])
                }
            }
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = addChildren(element.children[i], selected, title, level, childTitle, childId);
            }
            return result;
        }
        return null;
    }

    const removeItem = (element, selected) => {
        let canBeDeleted = true
        if (element.children) {
            for (let i = element.children.length - 1; i >= 0; i--) {
                let temp = element.children[i]
                if (temp.key == selected) {
                    if (parseInt(temp.level) !== 1) {
                        //children pos should be removed
                        if (temp.children) {
                            temp.children.forEach(it => {
                                if (it.posId) {
                                    notify("groups.deleteUnsuccessful", { type: 'error' })
                                    canBeDeleted = false
                                }
                                if (it.children) {
                                    it.children.forEach(childrenOfChildren => {
                                        //children of children thats the deepest it can be deleted
                                        if (childrenOfChildren.posId) {
                                            notify("groups.deleteUnsuccessful", { type: 'error' })
                                            canBeDeleted = false
                                        }
                                    })
                                }
                            })
                        }
                    }

                    if (canBeDeleted) {
                        element.children.splice(i, 1)
                        notify("groups.deleteSuccessful", { type: 'success' })
                    }

                }
            }
            element.children.forEach(child => removeItem(child, selected))
        }
    }

    return (
        <div className="draggable-demo">

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <div className={"groupDialog"}>
                    <DialogTitle id="alert-dialog-title">
                        {translate('groups.editNode')}
                    </DialogTitle>
                    <TextInput source="parentName" label={"groups.name"} validate={required()}
                               inputProps={{maxLength: 255}}/>
                    {editNode.level > 2 ?
                        <Button variant={'contained'} style={{color: "white"}} color={"primary"}
                                onClick={handleAddChild}><AddIcon/> {translate("groups.addChild")}</Button>
                        : null
                    }
                    {addChild &&
                    <div style={{display: 'flex', flexDirection: 'column'}}>

                        <FormLabel>Típus</FormLabel>
                        <AutocompleteInput source="groupType" label={"groups.type"} validate={required()} choices={filteredGroupTypes}/>
                        <FormDataConsumer>
                            {({formData, ...rest}) =>
                                formData.groupType > 1 ?
                                    <TextInput source="childName" label={"groups.name"} validate={required()} inputProps={{maxLength: 255}}/>
                                    :
                                    null
                            }
                        </FormDataConsumer>
                    </div>
                    }

                    <FormDataConsumer>
                        {({formData, ...rest}) =>
                            <div className="editNodeToolbar">
                                <Button style={{marginTop: "8px", marginRight: "8px", color: "white"}} variant={"contained"}
                                        color={"primary"}
                                        onClick={event => handleEditNode(event, formData)}><Save/>{translate(addChild? "Hozzáadás" : "ra.action.save")}
                                </Button>
                                {((editNode.level === 2 || editNode.level === 3) && !addChild) ?
                                    <Button style={{marginTop: "8px", marginRight: "8px"}} variant={"outlined"}
                                            color={"secondary"}
                                            onClick={handleDelete}><DeleteIcon/>{translate("ra.action.delete")}</Button>
                                    :
                                    null
                                }

                            </div>
                        }
                    </FormDataConsumer>
                </div>
            </Dialog>
            <Typography style={{marginRight: "8px"}} variant={"h4"}>{translate("groups.title")} </Typography>
            <div style={{display: "flex", marginTop: "8px", marginBottom: "8px"}}>
                {expanded ?
                    <>
                        <UnfoldLessDoubleIcon style={{color:"#ff71bc"}} onClick={handleCollapseAll}/>
                        <Typography variant={"p"} style={{verticalAlign: "middle"}} >{translate("groups.collapseAll")} </Typography>
                    </>
                    :
                    <>
                        <UnfoldMoreDoubleIcon  style={{color:"#ff71bc"}} onClick={handleExpandAll}/>
                        <Typography variant={"p"}>{translate("groups.expandAll")} </Typography>
                    </>
                }

            </div>
            <div className="draggable-container">
                {gData && gData.length > 0 ?
                    <Tree
                        treeData={gData}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                        autoExpandParent={autoExpandParent}
                        draggable
                        onDragStart={onDragStart}
                        onDrop={onDrop}
                        showLine
                        onSelect={onSelect}
                        icon={Icon}
                        switcherIcon={SwitcherIcon}
                    />
                    :
                    <LinearProgress/>
                }
            </div>
        </div>
    );
}

export const GroupCreate = ({permissions}) => {
    return (
        <Create title={"groups.title"} transform={groupTransform} redirect={false}>
            <SimpleForm toolbar={<PinkToolbar deleteEnabled={false} alwaysEnableSave={true}/>}>
                <Group/>
            </SimpleForm>
        </Create>
    )
}