import {Create, TabbedForm, useDataProvider, useNotify, useRedirect} from "react-admin";
import {CompanyDetails} from "./CompanyDetails";
export const CompanyCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider()

    const onSuccess = (data) => {
        dataProvider.get("company").then(result => {
                if(result.data.content.length === 1){
                    notify("company.created", {type: "success"})
                    redirect("/businessUnit")
                    window.location.reload(false)
                }else {
                    notify("company.created", {type: "success"})
                    redirect("/company")
                }
            }

        )
    };
    return (
        <Create mutationOptions={{onSuccess}}>
            <CompanyDetails />
        </Create>
    )
}