import Privileges from "./Privileges";

export function PermissionHandler(data = []) {
    const granted = Object.fromEntries(
        data.map(value => [value.id, value])
    );
    const hasPrivilege = (privilege) => {
        return !!granted[privilege.id];
    }

    const hasAny = (...privileges) => {
        const arr = privileges.flat(Infinity);
        if (arr.length === 0) {
            return true;
        }

        for (const privilege of arr) {
            if (hasPrivilege(privilege)) {
                return true;
            }
        }

        return false;
    }

}

const getValidBusinessUnitPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.BusinessUnit.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.Admin.BusinessUnit.Get.id)
            break;
        case 'GET':
            privileges.push(Privileges.Privilege.Admin.BusinessUnit.Get.id)
            break;
        case 'CREATE':
            privileges.push(Privileges.Privilege.Admin.BusinessUnit.Create.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.BusinessUnit.Update.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.Admin.BusinessUnit.Delete.id)
            break;
    }
}

const getValidProductPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.Product.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.Admin.Product.Get.id)
            break;
        case 'GET':
            privileges.push(Privileges.Privilege.Admin.Product.Get.id)
            break;
        case 'CREATE':
            privileges.push(Privileges.Privilege.Admin.Product.Create.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.Product.Update.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.Admin.Product.Delete.id)
            break;
    }
}

const getValidGroupPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.PosHierarchy.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.Admin.PosHierarchy.Get.id)
            break;
        case 'GET':
            privileges.push(Privileges.Privilege.Admin.PosHierarchy.Get.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.PosHierarchy.Update.id)
            break;
    }
}

const getValidDevicePrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.Device.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.Admin.Device.Get.id)
            break;
        case 'GET':
            privileges.push(Privileges.Privilege.Admin.Device.Get.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.Device.Update.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.Admin.Device.Delete.id)
            break;
    }
}

const getValidUserPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.User.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.Admin.User.Get.id)
            break;
        case 'GET':
            privileges.push(Privileges.Privilege.Admin.User.Get.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.User.Update.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.Admin.User.Delete.id)
            break;
    }
}

const getValidPriceListPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.PriceList.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.Admin.PriceList.Get.id)
            break;
        case 'GET':
            privileges.push(Privileges.Privilege.Admin.PriceList.Get.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.PriceList.Update.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.Admin.PriceList.Delete.id)
            break;
    }
}

const getValidPriceListToPosSetPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.PriceListToPosSet.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.Admin.PriceListToPosSet.Get.id)
            break;
        case 'GET':
            privileges.push(Privileges.Privilege.Admin.PriceListToPosSet.Get.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.PriceListToPosSet.Update.id)
            break;
    }
}

const getValidReportPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.Report.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.Admin.Report.Get.id)
            break;
        case 'GET':
            privileges.push(Privileges.Privilege.Admin.Report.Get.id)
            break;
        case 'CREATE':
            privileges.push(Privileges.Privilege.Admin.Report.Create.id)
            break;
    }
}

const getValidPosPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.CorporateAdmin.POS.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.CorporateAdmin.POS.List.id)
            break;
        case 'CREATE':
            privileges.push(Privileges.Privilege.CorporateAdmin.POS.Create.id)
            break;
        case 'DELETE':
            privileges.push(Privileges.Privilege.CorporateAdmin.POS.Delete.id)
            break;
    }
}

const getValidPosWithPriceListPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.PosWithPriceList.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.Admin.PosWithPriceList.Get.id)
            break;
    }
}

const getValidCompanyPrivileges = (method, privileges) => {
    privileges.push(Privileges.Privilege.Admin.Company.id)
    switch (method) {
        case 'LIST':
            privileges.push(Privileges.Privilege.Admin.Company.Get.id)
            break;
        case 'EDIT':
            privileges.push(Privileges.Privilege.Admin.Company.Update.id)
            break;
    }
}

export const getValidPrivilegesIds = (resource, method, cabe) => {
    let privileges = []
    //base privilege can access anything
    privileges.push(Privileges.Privilege.id)
    if (cabe) {
        //TODO code here the corporate admin privileges
        privileges.push(Privileges.Privilege.CorporateAdmin.id)
        switch (resource){
            case "POS":
                getValidPosPrivileges(method, privileges)
                break
        }
    } else {
        privileges.push(Privileges.Privilege.Admin.id)
        switch (resource) {
            case 'BUSINESS_UNIT':
                getValidBusinessUnitPrivileges(method, privileges)
                break
            case "GROUP":
                getValidGroupPrivileges(method, privileges)
                break
            case "DEVICE":
                getValidDevicePrivileges(method, privileges)
                break
            case "PRODUCT":
                getValidProductPrivileges(method, privileges)
                break
            case "USER":
                getValidUserPrivileges(method, privileges)
                break
            case "PRICELIST":
                getValidPriceListPrivileges(method, privileges)
                break
            case "PRICELIST_TO_POSSET":
                getValidPriceListToPosSetPrivileges(method, privileges)
                break
            case "REPORT":
                getValidReportPrivileges(method, privileges)
                break
            case "POS_WITH_PRICELIST":
                getValidPosWithPriceListPrivileges(method, privileges)
                break
            case "COMPANY":
                getValidCompanyPrivileges(method, privileges)
                break
        }
    }
    return privileges
}

export const setBusinessUnitPermissions = (privileges, resources) => {
    resources.businessUnit.hasList = false
    resources.businessUnit.hasEdit = false
    const privilegeIds = privileges.map(it => it.id)
    const privilegeNames = privileges.map(it => it.name)
    const businessUnitBasePrivileges = [Privileges.Privilege.id, Privileges.Privilege.children.BusinessUnit.id]
    const businessUnitGetPrivileges = [...businessUnitBasePrivileges, Privileges.Privilege.children.BusinessUnit.children.Get.id]
    const businessUnitUpdatePrivileges = [...businessUnitBasePrivileges, Privileges.Privilege.children.BusinessUnit.children.Update.id]
    for (let i = 0; i < privilegeIds.length; i++) {
        if (businessUnitGetPrivileges.includes(privilegeIds[i])) {
            resources.businessUnit.hasList = true
        }

        if (businessUnitUpdatePrivileges.includes(privilegeIds[i])) {
            resources.businessUnit.hasEdit = true
        }
        console.log("xxxx", resources)
    }
}