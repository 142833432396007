import hungarianMessages from 'ra-language-hungarian';
import {mergeDeep} from "../tools/mergeDeep";
import React from "react";

const missingTranslations = {
    ra: {
        action: {
            confirm: 'Megerősítés',
            search: 'Keresés',
            next: 'Tovább',
            finish: 'Befejezés',
            edit: 'Szerkesztés',
            select_columns: "Oszlopok szűrése"
        },
        page: {
            empty: 'Nincsenek még %{name}.',
            invite: 'Szeretne most hozzáadni egyet?',
        },
        navigation: {
            page_rows_per_page: 'Elemek száma oldalanként',
            skip_nav: "Navigáció elhagyása"
        },
        boolean: {
            null: ' '
        },
        configurable: {
            customize: "Személyreszabás"
        }
    }
};
const data = {
    common: {
        columns: "Oszlopok",
        columnSelected: "oszlop kiválasztva"
    },
    products : {
        title: "Termékek",
        id: "ID",
        name: "Név",
        create: "Új termék hozzáadása",
        mainCategory: "NTAK Főkategória",
        subCategory: "NTAK Alkategória",
        vatGroup: "ÁFA csoport",
        price: "Ár",
        productUnits: "Termék kiszerelései",
        quantity: "Mennyiség",
        unit: "Mértékegység",
        defaultPrice: "Alapértelmezett bruttó ár",
        greaterThanZero: "Az értéknek nagyobbnak kell lennie mint 0",
        empty: "Nincsenek még termékek",
        tags: "Címkék",
        filterTags: "Cimkék szűrése",
        dialogTitle: "Törlés megerősítése",
        dialogContextText: "Biztos szeretnéd tötölni a címkét? Az összes termékről el fogja távolítani azt",
        yes: "Igen",
        no: "Nem"
    },
    priceListTemplate : {
        title: "Árlapok",
        id: "ID",
        name: "Árlap sablon egyedi neve",
        create: "Új Árlap sablon létrehozása",
        createTemplate: "Új sablon",
        created: "Árlap létrehozva",
        createFail: "Hiba az árlap létrehozásánál",
        generalData: "Általános adatok",
        managingProducts: "Termékek hozzáadása / eltávolítása",
        changePrices: "Árak módosítása",
        addProductsToPriceList: "Termékek Hozzáadása árlaphoz",
        noProductInPriceList: "Jelenleg nincs egy termék se az árlistában",
        edit: "Szerkesztés",
        delete: "Törlés",
        saveAndActivate : "Mentés és Aktiválás",
        saveAsTemplate : "Mentés",
        saveAsInstance : "Árlap mentés",
        successfulSave: "Sikeres mentés",
        draft: "Vázlat",
        activated: "Aktivált",
        uniqueNameRequired: "Új egyedi név megadása kötlező",
        state: "Állapot",
        price: "Ár",
        modify: "Módosítás",
        cancel: "Mégse",
        empty: "Nincsenek még árlapok",
        nameShort: "Árlapok",
        createPriceList: "Új árlap létrehozása",
        createdAt: "Létrehozás dátuma",
        template: "Árlap sablon",
        templates: "Árlap sablonok"
    },
    businessUnits : {
        title: "Üzleti egységek",
        id: "ID",
        name: "Név",
        create: "Új Üzleti egység létrehozása",
        publicDomainName: "Közterület neve",
        publicDomainType: "Közterület jellege",
        number: "Házszám",
        settlement: "Település",
        zipCode: "Irányítószám",
        address: "Cím",
        empty: "Nincsenek még üzleti egységei",
        company: "Cég"
    },
    technicalUsers : {
        title: "Felhasználók",
        id: "ID",
        name: "Név",
        create: "Új technikai felhasznló felvétele",
        pinCode: "Pin kód",
        pinCodeCreate: "Pin kód (4 jegyű)",
        pinCodeAgain: "Pin kód újra (4 jegyű)",
        technicalUserData: "Technikai felhasználó adatai",
        technicalUserRole: "Felhasználó szerepköre",
        posSets: "Szerepkörhöz tartozó csoportok",
        resetPIN: "PIN kód csere",
        userTypeFilter: "Felhasználó típus szűrő",
        technical: "Technikai felhasználó",
        registered: "Regisztrált felhasználó",
        type: "Felhasználó Típusa",
        validation :{
            pinDontMatch: "A két PIN kód nem egyezik meg"
        }
    },
    groups: {
        title: "Csoportok kezelése",
        cantDrag: "Nem helyzehető át alacsonyabb hierachia szintre",
        editNode: "Csoport szerkesztése",
        addChild: "Gyerek hozzáadása",
        name: "Név",
        type: "Csoport típusa",
        pos: "POS",
        bar: "Pult",
        area: "Terület",
        businessUnit: "Üzleti egység",
        editSuccessful : "Sikeresen módosította a csoportot",
        deleteSuccessful : "Elem törlve",
        poss: "POS-ok",
        edit: "Kattintson a szerkeszteni kivánt csoportra",
        deleteUnsuccessful: "Nem törölhető csoport amg tartozik hozzá POS",
        expandAll: "Összes csoport kinyitása",
        collapseAll: "Összes csoport bezárása",
        add: "Hozzáadás"
    },
    activePosSets: {
        title: "Üzleti egység választó",
        posSets: "Üzleti egységek",
        change: "Üzleti egység kiválasztása",
        noActive: "Nincsenek még üzleti egységei",
        setActivePosSet: "Válasszon egy atív üzleti egységet"
    },
    priceListToPosSets: {
        title: "Árlap leküldése",
        priceLists: "Árlapok",
        priceList: "Árlap",
        currentPriceList : "Jelenlegi árlap",
        changePriceList: "Árlap csere",
        newPriceList: "Új árlap",
        updated: "Árlap frissítve",
        error: "Hiba az árlap frissítés során"
    },
    device: {
        empty: "Nincsenek még eszközök",
        title: "Eszközök kezelése",
        posId: "POS ID",
        posName: "POS Név",
        deviceId: "Eszköz ID",
        deviceName: "Eszköz Név",
        remove: "Leválasztás POS-ról",
        shiftOpen: "Jelenleg van nyitott műszak",
        cantRemove: "Nem lehet leválasztani az eszközt",
        detachAreYouSure: "Biztosan leválasztja az eszközt",
        removeShort: "Leválasztás"
    },
    pos: {
        title: "POS-ok létrehozása",
        add: "Új POS felvétele",
        name: "POS név",
        edit: "Módosítás",
        cancel: "Mégse"
    },
    report: {
        title: "Jelentések",
        add: "Jelentés létrehozása",
        type: "Jelentés típus",
        created: "Jelentés sikeresen elkészült",
        download: "Letöltés",
        reset: "Visszaállítás",
        date:{
            start: "Időszak kezdete",
            end: "Időszak vége",
            endBeforeStartError: "A vége dátum kisebb mint a kezdet"
        }
    },
    quickMenu: {
        title: "Gyors menü",
        add: "Termék hozzáadása a gyorsmenühöz",
        addShort: "Hozzáadás",
        success: "A terméket sikeresen hozzáadtad a gyorsmenühöz",
        full: "A gyorsmenüben már nincs szabad hely, nem tudtad hozzáadni a gyorsmenühöz",
        error: "Hiba a hozzáadás során, kérlek próbáld újra",
        addText: "Termék beszúrásához kattintson a kívánt cellába",
        choosePriceList: "Válasszon árlapot, utána szerkesztheti"
    },
    priceListsOfPos: {
        title: "POS-on levő árlapok",
        id: "POS ID",
        name: "POS Név",
        priceListId: "Árlap Id",
        priceListName: "Árlap Név",
        priceListValidFrom: "Érvényesség kezdete",
        priceLists: "Árlapok",
        open: "Megnyitás",
        search : "Keresés",
        createdAt: "Létrehozás dátuma"
    },
    company: {
        title: "Cég",
        id: "ID",
        name: "Név",
        create: "Új cég létrehozása",
        publicDomainName: "Közterület neve",
        publicDomainType: "Közterület jellege",
        number: "Házszám",
        settlement: "Település",
        zipCode: "Irányítószám",
        address: "Cím",
        empty: "Nincsenek még cégei",
        taxNumber: "Adószám",
        companyRegistrationNumber: "Cégjegyzékszám",
        euTaxNumber: "Közösségi adószám",
        contactName: "Kontakt személy",
        contactEmail: "Kontakt email",
        contactPhone: "Kontakt telefonszám",
        phonePrefix: "Előhívó szám",
        general: "Általános adatok",
        contacts: "Kapcsolat",
        created: "Cég sikeresen létrehozva"
    },
    dashboard:{
        title: "Vezérlőpult",
        activeWorkday: "Aktív munkanap",
        dateRangeFilter: "Időszak szűrő",
        date:{
            start: "Időszak kezdete",
            end: "Időszak vége",
            endBeforeStartError: "A vége dátum kisebb mint a kezdet"
        },
        refresh: "Frissítés",
        filter: "Szűrés",
        sumPurchase: "Vásárlás Szum",
        sumTip: "Borravaló Szum",
        sumCanceled: "Sztornó Szum",
        total: "Összes Szum",
        averageCartValue: "Átlagos kosárérték",
        averageTip: "Átlagos borravaló",
        countPurchase: "Tranzació száma",
        product: "TOP 10 termék",
        bar: "TOP 10 Pult",
        distributionRatio: "Tranzakció eloszlási arány",
        cash: "Készpénz",
        helloPay: "Hellopay",
        otp: "Bankkártya",
        otp_manual: "Bankkártya (manual)",
        fiveMin: "5 perc",
        fifteenMin: "15 perc",
        oneHour: "1 óra",
        trafficCount: "Forgalom - darabszám",
        trafficSum: "Forgalom - összesítve",
        endBeforeStartError: "A vége dátum kisebb mint a kezdet",
        moreThanOneDayInterval: "Az intervallum nem lehet nagyobb 24 óránál",
        endIsTooMuch: "A vége dátum túl nagy",
        trxCount: "Tranzakció db",
        tip: "Borravaló",
        noWorkday: "Nincs nyitott munkanap"
    },
    unit:{
        pieces: "db",
        huf: "Ft"
    }
}

const signUp  = {
    signUp : {
        login: "Bejelentkezés",
        forgotPassword: "Elfelejtett jelszó",
        registration: "Regisztráció",
        verification: "Ellenőrzés",
        email: "Email",
        password: "Jelszó",
        newPassword: "Új jelszó",
        newPasswordAgain: "Új jelszó ismét",
        passwordDifferentException: "A jelszavak különbözőek",
        verificationCodeRequest: "Ellenőrző kód kérés",
        save: "Mentés",
        givenName: "Keresztnév",
        familyName: "Vezetéknév",
        phone: "Telefon",
        pin: "PIN",
        phoneHint: "Formátum: +xxxxxxxxxxx pl:+36201234567",
        pinHint: "Csak 4 darab számjegyet tartalmazhat",
        passwordHint: "Tartalmaznia kell legalább 1 kisbetűt, 1 nagybetűt, 1 számot és 1 speciális karaktert (^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + = )",
        verificationCode: "Ellenörző kód",
        verificationCodeSent: "Ellenörző kód elküdve. A kódot az email fiókjában találja.",
        signIn: "Belépés",
        signUp: "Regisztráció",
        notAuthorizedException: "Rossz felhasználónév vagy jelszó.",
        passwordEmpty: "A jelszó nem lehet üres.",
        passwordPolicy: "A jelszó nem megfelelő. Min 8 hosszúnak kell lenni és tartalmaznia kell legalább 1 kisbetűt, 1 nagybetűt, 1 számot és 1 speciális karaktert(^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + = ) ",
        accountExist: "Már létezik felhasználó ezzel az email címmel.",
        givenNameEmpty: "Nem lehet üres a keresztnév.",
        familyNameEmpty: "Nem lehet üres a vezetéknév.",
        phoneNotValid: "Telefonszám formátum nem megfelelő. Helyes formátum: +36xxxxxxxxx",
        pinEmpty: "PIN formátum nem megfelelő. Csak számokat tartamazhat.",
        invalidVerificationCode: "Hibás ellenörző kód, próbálja újra.",
        expiredVerificationCode: "Lejárt az ellenörző kód, próbálja újra.",
        invalidCode: "Érvénytelen kódot adott meg, kérjen újra egy kódot.",
        limitExceededException: "Túllépte a kísérleti korlátot, próbálja meg egy idő után.",
        successfulRegistration: "Sikeres regisztráció. Ellenörző kódot az email fiókjában találja.",
        successfulVerification: "Ellenörzés sikeres. Most már be tud jelentkezni az oldalra."
    }
}

const hunMessages = mergeDeep(hungarianMessages, missingTranslations, data, signUp);


export default hunMessages;