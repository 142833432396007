import { defaultTheme } from 'react-admin';

export const theme ={
    ...defaultTheme,
    palette: {
        primary: {
            main: '#ff71bc',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#ff71bc',
            dark: '#ff71bc',
            green: '#38BA9B',
            red: '#EF4E69',
            contrastText: '#fff',
        },
        action:{
            disabled: '#000 !important',
        }
    },
    components: {
        ...defaultTheme.components,
        RaDatagrid: {
            styleOverrides: {
                root: {
                    "& .RaDatagrid-headerCell": {
                        backgroundColor: "#ff71bc",
                        borderBottom: '2px solid #ff71bc',
                        borderRight: '2px solid #fff',
                        color: "#fff"
                    },
                }
            }
        },

        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    "& .RaDatagrid-headerCell": {
                        backgroundColor: "#ff71bc",
                        borderBottom: '2px solid #ff71bc',
                        borderRight: '2px solid #fff',
                        color: "#fff"
                    },
                }
            }
        },


        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    // invisible border when not active, to avoid position flashs
                    borderLeft: '10px solid transparent',
                    '&.RaMenuItemLink-active': {
                        borderLeft: '10px solid #ff71bc',
                    },
                },
            },
        },
    },
};