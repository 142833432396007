import GridLayout from "react-grid-layout";
import React, {useEffect, useState} from "react";
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import {Button, Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import './quickMenu.css'
import {
    AutocompleteInput,
    ReferenceInput,
    required,
    FormDataConsumer, useDataProvider, useTranslate, useNotify
} from "react-admin"
import {useFormContext, useWatch} from "react-hook-form";
import {uuidv4} from "../../tools/generate";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import moment from "moment-timezone";
import Products from "../products";



export const QuickMenu = () => {
    const [data, setData] = React.useState({loading: false, loaded: false, data: [], error: undefined});

    const dataProvider = useDataProvider()
    const {setValue} = useFormContext();
    const priceListId = useWatch({ name: 'priceListId' });
    const priceListInstanceId = useWatch({ name: 'priceListInstanceId' });
    const [products, setProducts] = useState( [])
    const [layout, setLayout] = useState( [])
    const [open, setOpen] = useState(false)
    const [coo, setCoo] = useState({x: 0, y: 0})
    const [isDragged, setIsDragged] = useState( false)
    const translate = useTranslate()
    const COLUMN_SIZE = 6
    const ROW_SIZE = 7
    const ROW_HEIGHT = 90
    const TABLE_WIDTH = 1400
    const notify = useNotify()
    const format = "YYYY.MM.DD HH:mm"


    const getQuickMenu = (queryId) => {
        dataProvider.get(`quickMenu/${queryId}`)
            .then(value => {
                const dataExtended = value.data.map(it => { return {...it, uuid: uuidv4()}})
                setProducts(
                    dataExtended.map(it => {
                        return {i: it.uuid, name: it.name, unit: it.unit, price: it.price, id: it.id}
                    })
                )
                setLayout( dataExtended.map(it => {
                    return {i: it.uuid, x: it.x, y: it.y, w: it.width, h: it.height, id: it.id.toString()}
                }))
            })
            .catch(reason => {
                setData({loading: false, loaded: true, data: undefined, error: reason});
                setProducts([])
                setLayout([])
            })

    }

    const handleAdd = (productId, priceListId) => e => {
        if(productId && priceListId) {
            let totalArea = 0
            layout.forEach(it => {
               totalArea =  totalArea + (it.w * it.h)
            })
            if(totalArea >= COLUMN_SIZE * ROW_SIZE){
                notify("quickMenu.full", {type: "error"})
            }else {
                dataProvider.get(`priceList/${priceListId}/products`, {filter: `{"id":[${productId}]}`})
                    .then(value => {
                        setData({loading: false, loaded: true, data: value.data, error: undefined});
                        const newId = uuidv4()
                        setProducts([...products, {
                            i: newId,
                            name: value.data[0].product.name,
                            unit: value.data[0].unit,
                            price: value.data[0].price,
                            id: value.data[0].id,
                        }])
                        setLayout([...layout, {i: newId, x: coo.x, y: coo.y, w: 1, h: 1}])
                        notify("quickMenu.success", {type: "success"})
                    })
                    .catch(reason => {
                        setData({loading: false, loaded: true, data: undefined, error: reason});
                        notify("quickMenu.error", {type: "error"})
                    })
            }
            setOpen(false)
            setValue("productId", null)
            setCoo({x: 0, y: 0})
        }
    }

    useEffect(() => {
        if(priceListId) {
            const queryId = priceListInstanceId ? priceListInstanceId : priceListId
            getQuickMenu(queryId)
        }
    }, [priceListInstanceId]);

    useEffect(() => {
        if(priceListId) {
            setValue("priceListInstanceId", null)
            getQuickMenu(priceListId)
        }
    }, [priceListId]);

    const handleDialogClose = () => {
        setOpen(false)
        setCoo({x: 0, y: 0})
    }

    const handleDialogOpen = () => {
        setOpen(true)
    }

    const layoutHandle = (lay) => {
        //handles if the newly inserted item has collisions
        lay.map(it => {
            if(it.y > 6){
                it.y = 0
                it.x = it.x + 1
            }
        })
        setValue('layouts', lay)
        setValue("products", products)
        setLayout(lay)
    }


    const handleRemove = (removedId) => e => {
        e.stopPropagation();
            const newLayout = layout.filter(item => item.i != removedId)
            const newProducts = products.filter(item => item.i != removedId)
            setLayout(newLayout)
            setProducts(newProducts)
    }

    const getCoordinate = e =>
    {
        const elem = document.getElementById('clickCoordinate')
        const rect = elem.getBoundingClientRect()
        const x = Math.floor((e.clientX - rect.x)/(rect.width/COLUMN_SIZE))
        const y = Math.floor((e.clientY - rect.y)/(rect.height/ROW_SIZE))
        setCoo( {x, y})
        const found = (layout.find(obj => {return obj.x <= x && (obj.x+obj.w-1) >= x && obj.y <= y && (obj.y+obj.h-1) >= y }))
        //TODO "Ezt felül kell vizsgálni, mert nem jó megoldás a timeout, kiszámítathetatlan hogy eltérő eszközökön mennyi idő alatt fut le" - Dani
        setTimeout(() => {
            if(priceListId !== undefined && !isDragged && found === undefined) {handleDialogOpen()}
        }, 100)
    }

    const handleDragResize = () =>{
        setIsDragged(true)
        setTimeout(() => setIsDragged(false), 100)
    }

    return (
        <>
            <div style={{ width: '400px'}}>
            <ReferenceInput source="priceListId"
                            reference="priceList"
                            perPage={200}
                            sort={{ field: 'id', order: 'DESC' }}
            >
                <AutocompleteInput optionText={"name"} fullWidth={true} label={"priceListTemplate.template"}
                                   validate={required()}/>
            </ReferenceInput>
            {priceListId ?
                <>
                <Typography></Typography>
                <ReferenceInput source="priceListInstanceId"
                                reference="priceList/instances"
                                filter={{priceListTemplateId: priceListId}}
                                perPage={200}
                                sort={{ field: 'id', order: 'DESC' }}
                >
                    <AutocompleteInput optionText={((record)=> {
                        return  moment(new Date(record.createdAt)).format(format) + ' (' + record.id + ')'
                    })} fullWidth={true} label={"priceListTemplate.nameShort"}/>
                </ReferenceInput>
                </>
                :
                null
            }

                <FormDataConsumer>
                    {({formData}) => (
                        <>
                            <Dialog open={open} onClose={handleDialogClose} >
                                <DialogTitle>{translate("quickMenu.add")}</DialogTitle>
                                <DialogContent>
                                    <ReferenceInput source="productId"
                                                    reference={`priceList/${formData.priceListInstanceId ? formData.priceListInstanceId  : formData.priceListId }/products`}>
                                        <AutocompleteInput optionText={((record)=> {
                                            if(record.product) {
                                                return `${record?.product?.name} - ${record?.unit} - ${record?.price} Ft`
                                            }else{
                                                return ''
                                            }
                                        })} label={"products.name"}
                                                           validate={required()}/>
                                    </ReferenceInput>
                                    <Button id={"addFormButton"} style={{color: "white"}} variant={"contained"} disabled={!formData.priceListId}
                                            onClick={handleAdd(formData.productId,formData.priceListInstanceId ? formData.priceListInstanceId  : formData.priceListId)}
                                            size={"medium"}><AddIcon /> {translate("quickMenu.addShort")}
                                    </Button>
                                </DialogContent>
                            </Dialog>
                            {formData.priceListId ?
                                <>
                                <Button style={{marginBottom: "16px", color: "white"}} variant={"contained"} disabled={!formData.priceListId} onClick={handleDialogOpen} size={"medium"}><AddIcon /> {translate("quickMenu.add")}</Button>

                                <div style={{width: '1000px', padding: '8px'}}>
                                    <Typography variant={'h6'}>{translate("quickMenu.addText")}</Typography>
                                </div>
                                </>
                                :
                                <div style={{width: '1000px', padding: '8px'}}>
                                    <Typography variant={'h6'}>{translate("quickMenu.choosePriceList")}</Typography>
                                </div>

                            }
                        </>
                    )}
                </FormDataConsumer>



                </div>
                <div id="clickCoordinate" onClick={e => getCoordinate(e)}>
                    <div
                    style={{ width: '1405px',
                    height: '660px',
                    backgroundImage: "repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%)",
                    backgroundSize: "234px 94px"
                    }}>
                    <GridLayout
                        onLayoutChange={layoutHandle}
                        layout={layout}
                        className="layout"
                        cols={COLUMN_SIZE}
                        maxRows={ROW_SIZE}
                        rowHeight={ROW_HEIGHT}
                        width={TABLE_WIDTH}
                        containerPadding={[0, 0]}
                        margin={[4, 4]}
                        compactType={null}
                        preventCollision={true}
                        draggableCancel={".notDraggable"}
                        onDragStop={() => handleDragResize()}
                        onResizeStop={() => handleDragResize()}
                    >

                    {products.map((product, index) => (
                        <div  style={{backgroundColor: "#ff71bc", color: "white", display: "flex", flexDirection: "column"}}
                              key={product.i}
                        >
                            <div style={{textAlign: "right", marginBottom: "0px"}}>< ClearIcon className={"notDraggable"} onClick={handleRemove(product.i)} fontSize={"small"} style={{marginBottom:"0px"}}/></div>
                            <Typography onClick={e => e.preventDefault()} style={{textAlign: "center"}}>{product.name}</Typography>
                            <Typography fontSize={"small"} style={{textAlign: "center"}}>{product.unit}</Typography>
                            <Typography fontSize={"small"} style={{textAlign: "center"}}>{product.price} Ft</Typography>
                        </div>

                        )
                    )
                    }
                    </GridLayout>
                    </div>
                </div>
        </>
    );
}