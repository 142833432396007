import {SimpleForm, useTranslate, Toolbar, SaveButton, FormDataConsumer, useRecordContext} from "react-admin";
import React, {useEffect, useState} from "react";
import {TechnicalUserData} from "./TechnicalUserData";
import {Box, Button, Step, StepLabel, Stepper} from "@mui/material";
import {StepContent} from "@mui/material";
import {SetRoles} from "./SetRoles";
import {useFormState} from "react-hook-form";

export const UserDetails = ({edit, formData}) => {
    const translate = useTranslate()
    const [activeStep, setActiveStep] = React.useState(0);
    const [nextEnabled, setNextEnabled] = useState(false)
    const formState = useFormState()
    useEffect(() => {
        //TODO make logic more readable
        //makes next step button enabled if all necessary fields are filled and are valid
        if(Object.keys(formState.errors).length === 0 && Object.getPrototypeOf(formState.errors) === Object.prototype){
            if(edit && formData.name && ((formState.dirtyFields.pinCode && formState.dirtyFields.pinCodeAgain && formData.pinCode &&formData.pinCodeAgain) ||
                (!formState.dirtyFields.pinCode && !formState.dirtyFields.pinCodeAgain && !formData.pinCode && !formData.pinCodeAgain))) {
                setNextEnabled(true)
            }else{
                if(formState.dirtyFields.name && formState.dirtyFields.pinCode && formState.dirtyFields.pinCodeAgain){
                    setNextEnabled(true)
                }else{
                    setNextEnabled(false)
                }
            }
        }else{
            setNextEnabled(false)
        }
    }, [formState])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps = [
        {
            label: translate("technicalUsers.technicalUserData"),
            content: (
                <TechnicalUserData edit={edit}/>
            ),
            technicalOnly: true
        },
        {
            label: translate("technicalUsers.technicalUserRole"),
            content: (
                <SetRoles/>
            ),
            technicalOnly: false
        }

    ]

    return (


                    <>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                                (!step.technicalOnly || formData.isTechnical || !edit) &&
                                <Step key={step.label}>
                                    <StepLabel>
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        {step.content}
                                        <Box sx={{mb: 2}}>
                                            <div>
                                                <Button
                                                    style={{color: "white", marginRight: "8px"}}
                                                    disabled={!nextEnabled}
                                                    variant="contained"
                                                    color={"primary"}
                                                    onClick={handleNext}
                                                >
                                                    {index === steps.length - 1 ? translate('ra.action.finish') : translate('ra.action.next')}
                                                </Button>
                                                <Button
                                                    variant={"outlined"}
                                                    disabled={index === 0}
                                                    onClick={handleBack}
                                                >
                                                    {translate('ra.action.back')}
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>
                            )
                        )
                        }

                    </Stepper>
                        {activeStep === 2 ?
                            <Button onClick={handleBack} variant={"outlined"}> {translate('ra.action.back')}</Button>
                            :
                            null
                        }
                    </>
    )
}