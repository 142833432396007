import React, {useEffect, useState} from 'react';
import {
    TextField,
    List,
    SearchInput, useTranslate, useUnselectAll, Pagination, TopToolbar, ExportButton, FilterButton
} from 'react-admin';
import {Box, Button, Card, CircularProgress, Dialog, DialogContent, DialogTitle, Typography} from '@mui/material';
import {ProductSelectBulkAction} from "./ProductSelectBulkAction";
import {Datagrid as ReactAdminDataGrid} from "react-admin";
import EditPriceDataGrid from "./EditPriceDataGrid";
import AddIcon from '@mui/icons-material/Add';
import {EmptyPage} from "./EmtpyPage";


export const ProductToPriceList = (formData) => {
    const translate = useTranslate()
    const unselectAll = useUnselectAll('product/byUnit');

    const [open, setOpen] = useState(false);
    const openAddDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
        unselectAll()
    }

    const NoProducts = () => <Typography>{translate("products.empty")}</Typography>;

    const products = (formData.formData?.products) ? formData.formData.products : []
    const [filteredIds, setFilteredIds] = useState([])

    useEffect(() => {
        if(formData.formData.products) {
            setFilteredIds(formData.formData.products.map(product => product.id))
        }
    }, [products]);

    const ListItems = () => {
        if(products && products.length > 0) {
            return (
                <EditPriceDataGrid rows={products} />
            )
        }else{
            return <div> {translate("priceListTemplate.noProductInPriceList")} </div>
        }
    }

    const ListActions = () => (
        <div style={{marginBottom: "50px"}}>
            <ExportButton />
        </div>
    );


    const filters =  [<SearchInput sx={{marginBottom : "50px"}} source="q" alwaysOn={true} resettable={true}/>]
    return (
        <Box>
            <Button style={{marginBottom: "16px", color: "white"}} variant={"contained"} color={"primary"} onClick={openAddDialog}>
             <AddIcon />   {translate('priceListTemplate.addProductsToPriceList')}
            </Button>
            <Dialog open={open} fullWidth={true} scroll={"paper"} maxWidth={"lg"} onClose={handleClose} >
                <DialogTitle id="alert-dialog-title">
                    {translate('priceListTemplate.addProductsToPriceList')}
                </DialogTitle>
                <DialogContent>
                <div style={{maxHeight: "700px"}}>

            <List perPage={200} empty={<NoProducts />} actions={<ListActions />} pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />} resource={"product/byUnit"} filters={filters} filter={{excludedIds: filteredIds}}>
                    <ReactAdminDataGrid optimized={true} bulkActionButtons={<ProductSelectBulkAction current={products} setOpen={setOpen} />} >
                        <TextField source="product.name" label={"products.name"} />
                        <TextField source="unit" label={"products.unit"} sortable={false} />
                        <TextField source="product.vatGroup" sortable={false} label={"products.vatGroup"} />
                        <TextField source="product.mainCategory" sortable={false} label={"products.mainCategory"} />
                        <TextField source="product.subCategory" sortable={false} label={"products.subCategory"} />
                        <TextField source="price" sortable={false} label={"products.price"} />
                    </ReactAdminDataGrid>
            </List>
                </div>
                </DialogContent>
            </Dialog>
            <ListItems />
        </Box>
    );
};