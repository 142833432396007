import activePosSets from './pages/activePosSet'
import './App.css';
import * as React from "react";
import {Admin, Resource} from "react-admin";
import dataProvider from "./components/dataProvider";
import i18nProvider from "./locales/locale";
import {PinkLayout} from "./components/PinkLayout";
import authProvider from "./components/cognito/authProvider";
import pposLogin from "./components/cognito/PposLogin";
import {GroupCreate} from "./pages/groups/GroupCreate";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {getValidPrivilegesIds} from "./components/authentication/PermissionHandler";
import {BusinessUnitList} from "./pages/businessUnit/BusinessUnitList";
import {BusinessUnitCreate} from "./pages/businessUnit/BusinessUnitCreate";
import {BusinessUnitEdit} from "./pages/businessUnit/BusinessUnitEdit";
import StorefrontIcon from "@mui/icons-material/Storefront";
import {DeviceList} from "./pages/device/DeviceList";
import {DeviceEdit} from "./pages/device/DeviceEdit";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ProductList from "./pages/products/ProductList";
import ProductEdit from "./pages/products/ProductEdit";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import ProductCreate from "./pages/products/ProductCreate";
import UserList from "./pages/user/UserList";
import {TechnicalUserCreate} from "./pages/user/TechnicalUserCreate";
import {UserEdit} from "./pages/user/UserEdit";
import PersonIcon from "@mui/icons-material/Person";
import PriceListTemplateList from "./pages/priceListTemplate/PriceListTemplateList";
import {PriceListTemplateShow} from "./pages/priceListTemplate/PriceListTemplateShow";
import {PriceListTemplateEdit} from "./pages/priceListTemplate/PriceListTemplateEdit";
import {PriceListTemplateCreate} from "./pages/priceListTemplate/PriceListTemplateCreate";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CreditCard from "@mui/icons-material/CreditCard";
import {PosSetCreate} from "./pages/posSet/PosSetCreate";
import {ReportCreate} from "./pages/report/CreateReport";
import AssessmentIcon from '@mui/icons-material/Assessment';
import {QuickMenuCreate} from "./pages/quickMenu/QuickMenuCreate";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from "@mui/icons-material/Home";
import {getBusinessUnits} from "./components/authentication/BusinessUnits";
import {SendPriceListToPosSet} from "./pages/priceListToPosSets/SendPriceListToPosSet";
import {ActivePosSetSelector} from "./pages/activePosSet/ActivePosSetSelector";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {theme} from "./config/theme";
import {ListPriceListsOfPos} from "./pages/priceListToPosSets/ListPriceListsOfPos";
import AppsIcon from '@mui/icons-material/Apps';
import {CompanyList} from "./pages/company/CompanyList";
import BusinessIcon from '@mui/icons-material/Business';
import {CompanyEdit} from "./pages/company/CompanyEdit";
import {CompanyCreate} from "./pages/company/CompanyCreate";
import {getCompanies} from "./components/authentication/Companies";
import {Dashboard} from "./pages/dashboard/Dashboard";
import DashboardIcon from '@mui/icons-material/Dashboard';

const App = () => {
  return (
    <Admin dataProvider={dataProvider}
           authProvider={authProvider}
           loginPage={pposLogin}
           layout={PinkLayout}
           theme={theme}
           i18nProvider={i18nProvider}>
        {permissions =>
            <>
                <div>{console.log("permissions:", permissions)}</div>
              {getBusinessUnits() ?
                  <Resource name="activePosSet" options={{label: 'activePosSets.title'}}
                            list={getBusinessUnits() ? ActivePosSetSelector : null}
                            icon={HomeIcon}
                  />
                  : null
              }

              <Resource name="company" options={{label: 'company.title'}}
                        list={<CompanyList permissions={permissions} />}
                        create={CompanyCreate}
                        edit={getValidPrivilegesIds("COMPANY", "LIST").some(r => permissions.includes(r)) ? CompanyEdit : null}
                        icon={BusinessIcon}/>

              {getCompanies() ?
                  <Resource name="businessUnit" options={{label: 'businessUnits.title'}}
                            list={<BusinessUnitList permissions={permissions}/>}
                            create={BusinessUnitCreate}
                            edit={getValidPrivilegesIds("BUSINESS_UNIT", "EDIT").some(r => permissions.includes(r)) ? BusinessUnitEdit : null}
                            icon={StorefrontIcon}/>
                  : null
              }

        <Resource name="product" options={{label: 'products.title'}}
                  list={getValidPrivilegesIds("PRODUCT", "LIST").some(r => permissions.includes(r)) ? <ProductList permissions={permissions} /> : null}
                  edit={getValidPrivilegesIds("PRODUCT", "EDIT").some(r => permissions.includes(r)) ? <ProductEdit permissions={permissions} /> : null}
                  create={getValidPrivilegesIds("PRODUCT", "CREATE").some(r => permissions.includes(r)) ? <ProductCreate permissions={permissions} /> : null}
                  icon={FastfoodIcon}/>

        <Resource name="product/mainCategory"  />
        <Resource name="product/subCategory"  />
        <Resource name="product/taxCategory"  />
        <Resource name="product/byUnit"  />

        <Resource name="priceList" options={{label: 'priceListTemplate.title'}}
                  list={getValidPrivilegesIds("PRICELIST", "LIST").some(r => permissions.includes(r)) ? <PriceListTemplateList permissions={permissions} /> : null}
                  show={getValidPrivilegesIds("PRICELIST", "GET").some(r => permissions.includes(r)) ? <PriceListTemplateShow permissions={permissions} /> : null}
                  edit={getValidPrivilegesIds("PRICELIST", "EDIT").some(r => permissions.includes(r)) ? <PriceListTemplateEdit permissions={permissions} /> : null}
                  create={getValidPrivilegesIds("PRICELIST", "CREATE").some(r => permissions.includes(r)) ? <PriceListTemplateCreate permissions={permissions} /> : null}
                  icon={MenuBookIcon}/>

              <Resource name="priceListToPosSet" options={{label: 'priceListToPosSets.title'}}
                        list={getValidPrivilegesIds("PRICELIST_TO_POSSET", "LIST").some(r => permissions.includes(r)) ? <SendPriceListToPosSet permissions={permissions} /> : null}
                        icon={CloudDownloadIcon}/>

              <Resource name="quickMenu" options={{label: 'quickMenu.title'}}
                        list={getValidPrivilegesIds("PRICELIST", "CREATE").some(r => permissions.includes(r)) ? <QuickMenuCreate permissions={permissions} /> : null}
                        icon={AppsIcon}/>

            <Resource name="user" options={{label: 'technicalUsers.title'}}
                      list={getValidPrivilegesIds("USER", "LIST").some(r => permissions.includes(r)) ? <UserList permissions={permissions} /> : null}
                      create={getValidPrivilegesIds("USER", "CREATE").some(r => permissions.includes(r)) ? <TechnicalUserCreate permissions={permissions} /> : null}
                      edit={getValidPrivilegesIds("USER", "EDIT").some(r => permissions.includes(r)) ? <UserEdit permissions={permissions} /> : null}
                      icon={PersonIcon}/>

              <Resource name="group" options={{label: 'groups.title'}}
                        list={getValidPrivilegesIds("GROUP", "LIST").some(r => permissions.includes(r)) ? <GroupCreate permissions={permissions} /> : null}
                        icon={AccountTreeIcon} />

            <Resource name="device" options={{label: 'device.title'}}
                      list={getValidPrivilegesIds("DEVICE", "LIST").some(r => permissions.includes(r)) ? <DeviceList permissions={permissions} /> : null}
                      edit={getValidPrivilegesIds("DEVICE", "EDIT").some(r => permissions.includes(r)) ? <DeviceEdit /> : null}
                      icon={PointOfSaleIcon}/>

              <Resource name="report" options={{label: 'report.title'}}
                        list={getValidPrivilegesIds("REPORT", "CREATE").some(r => permissions.includes(r)) ? <ReportCreate permissions={permissions} /> : null}
                        icon={AssessmentIcon}/>

              <Resource name="pos/pricelist" options={{label: 'priceListsOfPos.title'}}
                        list={getValidPrivilegesIds("POS_WITH_PRICELIST", "LIST").some(r => permissions.includes(r)) ? <ListPriceListsOfPos permissions={permissions} /> : null}
                        icon={MenuIcon}/>

              <Resource name="dashboard" options={{label: 'dashboard.title'}}
                        list={getValidPrivilegesIds("POS_WITH_PRICELIST", "LIST").some(r => permissions.includes(r)) ? <Dashboard permissions={permissions} /> : null}
                        icon={DashboardIcon}/>

              <Resource name="posSet/posList" options={{label: 'pos.title'}}
                        list={getValidPrivilegesIds("POS", "CREATE", true).some(r => permissions.includes(r)) ? <PosSetCreate permissions={permissions} /> : null}
                        icon={CreditCard}/>
            </>
        }
    </Admin>
  );
}

export default App;
