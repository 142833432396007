import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DataGrid, huHU} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {useFormContext} from "react-hook-form";
import React, {useState} from "react";
import {useTranslate} from "react-admin";
import {Button, Dialog, DialogContent, TextField, Tooltip} from "@mui/material";
import {muiDatagridTheme} from "../../components/MuiDatagridTheme";

export const PosListDataGrid = ({rows}) => {
    const columns = [
        {
            field: 'name',
            headerName: 'Pos Név',
            width: 350
        },
        {
            field: 'deviceId',
            headerName: 'Eszköz ID'
        },
        {
            field: 'deviceName',
            headerName: 'Eszköz Név'
        },
        {
            field: "action",
            headerName: "Törlés",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const newRows = rows.filter(row => {
                        return row.id !== params.id
                    })
                    setValue('posSets', newRows)
                };
                if(params.row.isShiftOpen) {

                    return <Tooltip title="Jelenleg van nyitott műszak a POS-on ezért nem leválasztható">
                       <DeleteIcon color={"disabled"} style={{paddingLeft: "20px"}}/>
                    </Tooltip>
                }else{
                    return <Button style={{color: "#ff71bc"}} onClick={onClick}><DeleteIcon/></Button>;
                }
            }
        },
        {
            field: "editAction",
            headerName: "Módosítás",
            sortable: false,
            renderCell: (params) => {
                const onClick = (e) => {
                    setOpen(true)
                    setEditValue(params.row)
                };

                return <Button onClick={onClick}><EditIcon/></Button>;
            }
        }

    ];

    const { setValue } = useFormContext();
    const [open, setOpen] = useState(false)
    const [name, setName] = useState(null)
    const [editValue, setEditValue] = useState(null)
    const translate = useTranslate()

    const handleDialogClose = () => {
        setOpen(false)
    }


    const handleTextChange = (event) => {
        setName(event.target.value)
    }


    const handleClick = (event) => {
        const newRows = rows.map(row => {
            if(row.id !== editValue.id){
                return row
            }else{
                return {id: row.id, name: name, deviceId: row.deviceId, deviceName: row.deviceName}
            }
        })
        setValue('posSets', newRows)
        setOpen(false)

    };

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            handleClick(e)
        }
    };

    return (

        <React.Fragment>
            <Dialog open={open} onClose={handleDialogClose} >
                <DialogContent>
                    <TextField label={translate("pos.name")} autoFocus defaultValue={editValue?.name} onKeyPress={handleKeypress} onChange={handleTextChange}  />
                    <div style={{marginTop: "16px", display:"flex", justifyContent: "space-between"}}>
                        <Button style={{paddingRight: "8px"}} variant={"contained"} color={"primary"} onClick={handleClick}>{translate("pos.edit")}</Button>
                        <Button variant={"outlined"} color={"secondary"} onClick={handleDialogClose}>{translate("pos.cancel")}</Button>
                    </div>
                </DialogContent>
            </Dialog>
            <div style={{ height: 400, width: 1000 }}>
                <ThemeProvider theme={muiDatagridTheme}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        disableSelectionOnClick
                    />
                </ThemeProvider>
            </div>
        </React.Fragment>
    );
}