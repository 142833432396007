import * as React from "react";
import {
    Button, useDataProvider,
    useListContext, useTranslate, useUnselectAll,
} from 'react-admin';
import {useFormContext} from "react-hook-form";

export const ProductSelectBulkAction = ({current, setOpen}) => {
    const { selectedIds, data} = useListContext();
    const { setValue } = useFormContext();
    const translate = useTranslate()
    const unselectAll = useUnselectAll('product/byUnit');
    const dataProvider = useDataProvider()



    const updateMany = () => {
        dataProvider.get("product/byUnit", {size: 200}).then(x => {
            const filteredData = x.data.content.filter(item => selectedIds.includes(item.id))
            setValue('products', current.concat(filteredData))
        })

        setOpen(false)
        unselectAll()
    }


    return (
        <Button
            label= {translate("priceListTemplate.addProductsToPriceList")}
            onClick={updateMany}
        />
    );
};