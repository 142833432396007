import {
    AutocompleteInput,
    ReferenceInput,
    required,
    SimpleForm,
    Create,
    Toolbar, useNotify, useTranslate, SaveButton
} from "react-admin";
import React, {useState} from "react";
import {DateRangePicker} from "../../components/DateRangePicker";
import moment from "moment-timezone";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {Button, CircularProgress, Typography} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';


export const ReportCreate = () => {


    const ReportToolbar = () =>{
        const notify = useNotify()
        const translate = useTranslate()
        const [report, setReport] = useState(undefined)
        const [isLoading, setLoading] = useState(false)


        return (
            <Toolbar>
                <SaveButton
                    label="report.add"
                    icon={<AssessmentIcon />}
                    mutationOptions={{
                        onSuccess: (data) => {
                            notify('report.created');
                            const contentType = data.response.contentType
                            const base64Data = data.response.file
                            const fileName = data.response.fileName
                            const linkSource = `data:${contentType};base64,${base64Data}`;
                            setReport({file:linkSource, fileName: fileName})
                            setLoading(false)
                        }}
                    }
                    type="button"
                    variant="text"
                />
                { report ?
                    <>
                        <Typography style={{ marginLeft: "16px"}} variant={"h6"}>{report.fileName}</Typography>
                        <a download={report.fileName} href={report.file} style={{textDecoration: "none", marginLeft: "16px"}}>
                        <Button variant={"contained"} style={{color: "white"}} color={"primary"} >
                            <FileDownloadIcon />   {translate('report.download')}
                        </Button>
                        </a>
                    </>
                    :
                   null
                }
                {isLoading ?
                    <CircularProgress />
                    :
                    null
                }
            </Toolbar>
        )
    }

    return (

        <Create>
            <SimpleForm toolbar={<ReportToolbar />}>
                <ReferenceInput source="type"
                                reference="report/type">
                    <AutocompleteInput optionText={"name"} fullWidth={true} label={"report.type"}
                                       validate={required()}/>
                </ReferenceInput>
                <DateRangePicker source={{start: 'start', end: 'end'}} validate={required()}
                                 startDefault={moment().subtract(1, 'day')}
                                 endDefault={moment()}/>
            </SimpleForm>
        </Create>
    )
}