import {Create, useDataProvider, useNotify, useRedirect, useRefresh} from "react-admin";
import {BusinessUnitDetails} from "./BusinessUnitDetails";
import {traverse_tree} from "../../tools/groupTransform";

export const BusinessUnitCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider()
    const refresh = useRefresh()

    const onSuccess = (data) => {
        dataProvider.get("businessUnit").then(result => {
            if(result.data.content.length === 1){
                dataProvider.create('activePosSet', {data:{newId : data.id}})
                    .then(value => {
                        notify("activePosSets.setActivePosSet", {type: "success"})
                        redirect("/businessUnit")
                        window.location.reload(false)
                    })
            }else {
                notify("activePosSets.setActivePosSet", {type: "success"})
                redirect("/businessUnit")
            }
            }

        )
    };
    return (
        <Create mutationOptions={{onSuccess}}>
            <BusinessUnitDetails />
        </Create>
    )
}