import {
    ArrayInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    FormDataConsumer,
    AutocompleteInput,
    required
} from "react-admin";
import React, {useState} from "react";
import {SelectPosSets} from "./SelectPosSets";
import {useWatch} from "react-hook-form";

export const SetRoles = (props) => {
    const [selectedIds, setSelectedIds] = useState([])
    const HiddenDummyInput = () => {
        const roles = useWatch({ name: 'roles' });
        const ids = (roles && roles.length > 0 ) ? roles.map(it => it?.id).filter(it => it !== "" && it !== undefined) : []
        if(JSON.stringify(ids) != JSON.stringify(selectedIds)){
            setSelectedIds(ids)
        }
        return (
            <div/>
        )

    }
    return (
        <div style={{display: 'inline-flex', flexDirection: 'column'}}>
            <ArrayInput source={"roles"} label={"technicalUsers.technicalUserRole"}>
                <SimpleFormIterator disableReordering={true}>
                    <ReferenceInput source={"id"} reference={'user/roles'} filter={{selectedIds: selectedIds}}>
                        <AutocompleteInput optionText={"name"} label={"technicalUsers.technicalUserRole"} validate={required()}
                                     />
                    </ReferenceInput>
                    <HiddenDummyInput />
                    <FormDataConsumer>
                        {({getSource, scopedFormData, formData}) => {
                            if(scopedFormData) {
                                return(
                                <SelectPosSets index={scopedFormData} formDataCurrentPosSets={formData.roles}
                                               formDataPosSets={formData.updatedRoles} source={'posSet'} updatedResourceName={"updatedRoles"} disableAlreadySelected={false}/>
                                )
                            }else {
                                return(
                                <div></div>
                                )
                            }
                        }
                        }

                    </FormDataConsumer>

                </SimpleFormIterator>
            </ArrayInput>
        </div>
    )
}